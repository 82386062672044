var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "notes-modal",
          attrs: { title: "Notes", visible: _vm.open_ },
          on: {
            "update:visible": function($event) {
              _vm.open_ = $event
            },
            close: _vm.notesUpdateCanceled
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-lg-12" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 10,
                    placeholder: "Please type..."
                  },
                  model: {
                    value: _vm.notes_,
                    callback: function($$v) {
                      _vm.notes_ = $$v
                    },
                    expression: "notes_"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary cursor-pointer",
                  attrs: { type: "button", "close-on-click-modal": true },
                  on: { click: _vm.notesUpdateCanceled }
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary cursor-pointer",
                  attrs: { type: "button" },
                  on: { click: _vm.notesUpdated }
                },
                [_vm._v("\n        Save changes\n      ")]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }