var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("metronic-pagination", {
        attrs: {
          eventBus: _vm.eventBus,
          changeEventName: _vm.paginationChangeEventName,
          paginationConfig: _vm.paginationConfig
        }
      }),
      _vm._v(" "),
      _c("metronic-table", {
        key: _vm.metronicTableKey,
        attrs: {
          loader: _vm.loader,
          eventBus: _vm.eventBus,
          tableData: _vm.transactions,
          headers: _vm.headers
        },
        on: { rowDataUpdated: _vm.rowDataUpdated, searchQuery: _vm.searchQuery }
      }),
      _vm._v(" "),
      _c("metronic-pagination", {
        attrs: {
          eventBus: _vm.eventBus,
          changeEventName: _vm.paginationChangeEventName,
          paginationConfig: _vm.paginationConfig
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }