var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "m-portlet" }, [
        _c("div", { staticClass: "m-portlet__body" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "col-lg-12 swiper-accrual",
                class: {
                  "no-data": !_vm.loading && !_vm.snapshotCurrentData.length
                }
              },
              [
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      visible: _vm.accrualDeleteVisible,
                      width: "30%",
                      center: ""
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.accrualDeleteVisible = $event
                      }
                    }
                  },
                  [
                    _c("span", [_vm._v("Do you really want to delete ?")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer"
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.accrualDeleteVisible = false
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.removeLastAccrualMonth()
                              }
                            }
                          },
                          [_vm._v("Confirm")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.snapshotCurrentData[0]
                  ? [
                      _c(
                        "div",
                        { staticClass: "el-swiper-container" },
                        [
                          _c(
                            "el-carousel",
                            {
                              ref: "snapshot_slider",
                              attrs: {
                                trigger: "click",
                                height: "50px",
                                autoplay: false,
                                arrow: "never"
                              }
                            },
                            [
                              _c("el-button", {
                                staticClass: "left",
                                attrs: {
                                  icon: "el-icon-arrow-left",
                                  circle: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showPrevData()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm._l(3, function(item) {
                                return _c("el-carousel-item", { key: item }, [
                                  _c("h3", [
                                    _vm._v(
                                      "\n                    Snapshot for " +
                                        _vm._s(
                                          _vm._f("formatSnapshotName")(
                                            _vm.snapshotCurrentData[0]
                                              .snapshot_date,
                                            "YYYY-MM-DD"
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                    _vm.snapshotCurrentData[0].snapshot_details
                                      .snapshot_editable == 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "m-badge m-badge--wide m-badge--rounded m-badge--metal"
                                          },
                                          [_vm._v("Closed")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.snapshotCurrentData[0].snapshot_details
                                      .snapshot_editable == 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "m-badge m-badge--wide m-badge--rounded m-badge--info"
                                          },
                                          [_vm._v("Open")]
                                        )
                                      : _vm._e()
                                  ])
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-button", {
                                staticClass: "right",
                                attrs: {
                                  disabled: _vm.isFutureWeek,
                                  icon: "el-icon-arrow-right",
                                  circle: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showNextData()
                                  }
                                }
                              })
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading"
                            }
                          ],
                          key: _vm.snapshotTableKey,
                          staticClass: "snapshot-table",
                          staticStyle: { width: "100%", "min-height": "500px" },
                          attrs: {
                            id: "snapshot-table",
                            border: "",
                            data: _vm.snapshotCurrentData[0].accruals,
                            "row-class-name": _vm.tableRowClassName,
                            "show-summary": "",
                            "summary-method": _vm.getSummaries
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "left",
                              prop: "month",
                              label: "Month",
                              "min-width": "60",
                              width: "'100%'"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("date")(
                                              _vm._f("strToDate")(
                                                scope.row.interval_start,
                                                "YYYY-MM-DD"
                                              ),
                                              "MMM YYYY"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2579062821
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            staticClass: "snapshot-hours-col",
                            attrs: {
                              align: "right",
                              prop: "hours",
                              label: "Hours",
                              "min-width": "50",
                              width: "'100%'"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "editable-cell" },
                                        [
                                          _c("el-input-number", {
                                            staticClass: "snapshot-hours",
                                            attrs: {
                                              placeholder: "hours",
                                              min: 0,
                                              precision: 2,
                                              controls: false,
                                              "controls-position": "right",
                                              disabled:
                                                !_vm.updatePermission ||
                                                !scope.row.active,
                                              label: "Hours",
                                              size: "mini"
                                            },
                                            nativeOn: {
                                              change: function($event) {
                                                return _vm.updateAccrual(
                                                  scope.row.id,
                                                  scope.row.interval_start,
                                                  scope.row.hours,
                                                  $event,
                                                  "hourField"
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.hours,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "hours",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.hours"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3768931238
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "right",
                              prop: "completion",
                              label: "% Completion",
                              "min-width": "70",
                              width: "'100%'"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("percentage")(
                                            scope.row.revn
                                              ? parseFloat(scope.row.revn)
                                              : 0,
                                            _vm.snapshotCurrentData[0].accruals[
                                              _vm.snapshotCurrentData[0]
                                                .accruals.length - 1
                                            ].revenueCummulative,
                                            2
                                          )
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3349654722
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "right",
                              prop: "revenue",
                              label: "Revenue: Actual/Forecasted",
                              "min-width": "100",
                              width: "'100%'"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "editable-cell" },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              class: {
                                                positive:
                                                  scope.row
                                                    .revenueDifference === "up",
                                                negative:
                                                  scope.row
                                                    .revenueDifference ===
                                                  "down"
                                              },
                                              attrs: {
                                                type: "text",
                                                "trigger-on-focus": false,
                                                placeholder: "Revenue",
                                                min: 0,
                                                disabled:
                                                  !_vm.updatePermission ||
                                                  !scope.row.active,
                                                label: "Revenue",
                                                size: "mini"
                                              },
                                              nativeOn: {
                                                keyup: function($event) {
                                                  return _vm.checkForMaxValue(
                                                    scope.row.revenue,
                                                    $event
                                                  )
                                                },
                                                change: function($event) {
                                                  return _vm.updateAccrual(
                                                    scope.row.id,
                                                    scope.row.interval_start,
                                                    scope.row.revenue,
                                                    $event,
                                                    "revenueField"
                                                  )
                                                }
                                              },
                                              model: {
                                                value: scope.row.revenue,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "revenue",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.revenue"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "prepend" },
                                                [_vm._v("$")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3914650972
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "right",
                              prop: "revenueCummulative",
                              label: "Revenue Cummulative",
                              "min-width": "100",
                              width: "'100%'"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              scope.row.revenueCummulative
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              993135618
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "right",
                              prop: "invoice",
                              label: "Invoice Amount",
                              "min-width": "80",
                              width: "'100%'"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "m-link m--font-bold",
                                          class: {
                                            "amount-link":
                                              scope.row.active === true
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.updatePermission &&
                                                scope.row.active &&
                                                _vm.openInvoiceEditModal(
                                                  scope.row.invoices,
                                                  scope.row.id
                                                )
                                            }
                                          }
                                        },
                                        [
                                          !scope.row.invoices.length
                                            ? _c(
                                                "span",
                                                {
                                                  class: {
                                                    positive:
                                                      scope.row
                                                        .invoiceDifference ===
                                                      "up",
                                                    negative:
                                                      scope.row
                                                        .invoiceDifference ===
                                                      "down"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm._f(
                                                          "array_of_objects_sum"
                                                        )(
                                                          scope.row.invoices,
                                                          "value"
                                                        )
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    trigger: "hover",
                                                    placement: "left"
                                                  }
                                                },
                                                [
                                                  _vm._l(
                                                    scope.row.invoices,
                                                    function(invoice, index) {
                                                      return _c(
                                                        "ul",
                                                        {
                                                          key: index,
                                                          staticClass:
                                                            "invoice-container"
                                                        },
                                                        [
                                                          _c("li", [
                                                            _vm._v(
                                                              "Date: " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "bit_date_format"
                                                                  )(
                                                                    invoice.invoice_date
                                                                  )
                                                                )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("li", [
                                                            _vm._v(
                                                              "Amount: " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "currency"
                                                                  )(
                                                                    invoice.value
                                                                  )
                                                                )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        positive:
                                                          scope.row
                                                            .invoiceDifference ===
                                                          "up",
                                                        negative:
                                                          scope.row
                                                            .invoiceDifference ===
                                                          "down"
                                                      },
                                                      attrs: {
                                                        slot: "reference"
                                                      },
                                                      slot: "reference"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            scope.row
                                                              .totalInvoice
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                2
                                              ),
                                          _vm._v(" "),
                                          _vm.updatePermission &&
                                          scope.row.active
                                            ? _c("i", {
                                                staticClass:
                                                  "la la-pencil-square"
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1783420964
                            )
                          })
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  class: { "min-height-500": !_vm.snapshotCurrentData.length }
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("h3", { staticClass: "chart-header" }, [
                _vm._v("Revenue vs Invoice")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-lg-12",
                    class: { "no-data": !_vm.lineChartData.length }
                  },
                  [
                    _c("line-chart", {
                      attrs: {
                        data: _vm.lineChartData,
                        collection: ["Revenue", "Invoice"],
                        xAxisKey: "Month",
                        yAxisLabel: "Amount($)",
                        scrollBar: false,
                        balloonTextValuePrefix: "$",
                        showBalloonText: true
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("invoice-edit-dialog", {
        attrs: {
          accrualId: _vm.accrualId,
          open: _vm.editInvoice,
          currentInvoices: _vm.editInvoiceObject
        },
        on: {
          invoiceUpdated: _vm.updateInvoice,
          "update:open": function($event) {
            _vm.editInvoice = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }