var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-4" }, [
    _c("div", { staticClass: "m-widget4 m-widget12" }, [
      _c("div", { staticClass: "m-widget4__item m-widget12__item mb-0" }, [
        _c("div", { staticClass: "m-widget12__text2 m-widget4__info p-0" }, [
          _c("div", { staticClass: "m-widget12__desc" }, [
            _vm._v("Planned Progress")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "m-widget12__progress pt-2" }, [
            _c(
              "div",
              {
                staticClass: "m-widget12__progress-sm progress m-progress--sm"
              },
              [
                _c("div", {
                  staticClass:
                    "m-widget12__progress-bar progress-bar bg-success",
                  style: { width: _vm.status + "%" },
                  attrs: {
                    role: "progressbar",
                    "aria-valuenow": "25",
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "m-widget12__stats",
                staticStyle: { width: "20%" }
              },
              [_vm._v("\n            " + _vm._s(_vm.status) + "%\n          ")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-widget4__item" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "m-widget4__ext" }, [
          _vm.total_hours
            ? _c("span", { staticClass: "m-widget4__number m--font-info" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.total_hours.toFixed(1)) +
                    "\n        "
                )
              ])
            : _c("span", { staticClass: "m-widget4__number m--font-info" }, [
                _vm._v("\n          0\n        ")
              ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-widget4__item" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "m-widget4__ext" }, [
          _c("span", { staticClass: "m-widget4__number m--font-info" }, [
            _vm._v(
              "\n          $" +
                _vm._s(_vm.prettifyNumber(_vm.total_accrual)) +
                "\n        "
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-widget4__info pl-0" }, [
      _c("span", { staticClass: "m-widget4__text" }, [
        _vm._v("\n          Total Hours\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-widget4__info pl-0" }, [
      _c("span", { staticClass: "m-widget4__text" }, [
        _vm._v("\n          Total Accrual\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }