var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "m-accordion m-accordion--default m-accordion--toggle-arrow m--font-brand mt-lg-2",
      attrs: { id: "project_summary_panel", role: "tablist" }
    },
    [
      _c("div", { staticClass: "m-accordion__item" }, [
        _c(
          "div",
          {
            staticClass: "m-accordion__item-head collapsed",
            attrs: {
              srole: "tab",
              id: "project_summary_panel_head",
              "data-toggle": "collapse",
              href: "#project_summary_panel_body",
              "aria-expanded": "  false"
            }
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("span", { staticClass: "m-accordion__item-title" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.project_name) + " Summary\n      "
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "m-accordion__item-mode" })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "m-accordion__item-body collapse",
            attrs: {
              id: "project_summary_panel_body",
              role: "tabpanel",
              "aria-labelledby": "project_summary_panel_head",
              "data-parent": "#project_summary_panel"
            }
          },
          [
            _c("div", { staticClass: "m-accordion__item-content" }, [
              _c("div", { staticClass: "m-widget28" }, [
                _c("div", { staticClass: "m-widget28__container" }, [
                  _c("div", { staticClass: "m-widget28__tab mt-0" }, [
                    _c("div", { staticClass: "m-widget28__tab-container" }, [
                      _c("div", { staticClass: "m-widget28__tab-items" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Project Type")]),
                              _vm._v(" "),
                              _vm.contract_type
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.contract_type))
                                  ])
                                : _c("span", [_vm._v("-")])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Total Hours")]),
                              _vm._v(" "),
                              _vm.hours
                                ? _c("span", [_vm._v(_vm._s(_vm.hours))])
                                : _c("span", [_vm._v("-")])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Account Manager")]),
                              _vm._v(" "),
                              _vm.account_managers.length != 0
                                ? _c(
                                    "span",
                                    { staticClass: "managers" },
                                    _vm._l(_vm.account_managers, function(
                                      account_manager
                                    ) {
                                      return _c("span", [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(account_manager) +
                                            "\n                          "
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                : _c("span", [
                                    _vm._v(
                                      "\n                          -\n                        "
                                    )
                                  ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Project Manager(s)")]),
                              _vm._v(" "),
                              _vm.project_managers.length != 0
                                ? _c(
                                    "span",
                                    { staticClass: "managers" },
                                    _vm._l(_vm.project_managers, function(
                                      project_manager
                                    ) {
                                      return _c("span", [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(project_manager) +
                                            "\n                          "
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                : _c("span", [
                                    _vm._v(
                                      "\n                          -\n                        "
                                    )
                                  ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Start Date")]),
                              _vm._v(" "),
                              _vm.start_date
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("bit_date_format")(
                                          _vm.start_date
                                        )
                                      )
                                    )
                                  ])
                                : _c("span", [_vm._v("-")])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("End Date")]),
                              _vm._v(" "),
                              _vm.end_date
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("bit_date_format")(_vm.end_date)
                                      )
                                    )
                                  ])
                                : _c("span", [_vm._v("-")])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "m-accordion__item-icon" }, [
      _c("i", { staticClass: "flaticon-information" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }