var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-table",
    {
      attrs: {
        border: "",
        stripe: "",
        height: "350",
        data: _vm.tableRows,
        "highlight-current-row": "",
        "highlight-hover-row": "",
        size: "mini"
      }
    },
    _vm._l(_vm.headers, function(header) {
      return _c("vxe-table-column", {
        key: header.key,
        attrs: {
          prop: header.key,
          label: header.label,
          sortable: header.sortable,
          align: header.currency ? "right" : "left",
          filters: header.filter ? header.filterOptions : null,
          "filter-method": header.filter ? _vm.filterTag : null
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(scope) {
                return [
                  header.type == "tag"
                    ? _c("el-tag", [_vm._v(_vm._s(scope.row[header.key]))])
                    : header.currency === true
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("currency")(scope.row[header.key]))
                        )
                      ])
                    : _c("span", [_vm._v(_vm._s(scope.row[header.key]))])
                ]
              }
            }
          ],
          null,
          true
        )
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }