var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-xl-6 col-lg-12" }, [
    _c("div", { staticClass: "m-portlet m-portlet--full-height" }, [
      _c("div", { staticClass: "m-portlet__head" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.modify_permission,
                expression: "modify_permission"
              }
            ],
            staticClass: "m-portlet__head-tools"
          },
          [_vm._m(1)]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-portlet__body" }, [
        _c("div", { staticClass: "m-widget11" }, [
          _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _c(
                "vxe-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    "show-footer": "",
                    stripe: "",
                    "highlight-current-row": "",
                    "highlight-hover-row": "",
                    size: "small",
                    height: "400",
                    "footer-method": _vm.footerMethod,
                    data: _vm.resourceSummaryData
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "empty",
                      fn: function() {
                        return [
                          _c("span", { staticStyle: { color: "black" } }, [
                            _c("p", [_vm._v("No Data")])
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("vxe-table-column", {
                    attrs: {
                      prop: "resource_role",
                      title: "Role Title",
                      sortable: "",
                      "min-width": "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", { staticClass: "m-widget11__title" }, [
                              _vm._v(_vm._s(scope.row.resource_role))
                            ]),
                            _vm._v(" "),
                            scope.row.resource_role.includes("(IN)")
                              ? _c("span", { staticClass: "m-widget11__sub" }, [
                                  _vm._v("Bangalore")
                                ])
                              : _c("span", { staticClass: "m-widget11__sub" }, [
                                  _vm._v("Houston")
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("vxe-table-column", {
                    attrs: {
                      prop: "talent_name",
                      title: "Talent Name",
                      sortable: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                class: _vm.valueBasedFontClass(
                                  scope.row.talent_name
                                )
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(scope.row.talent_name) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("vxe-table-column", {
                    attrs: {
                      title: "Hours",
                      align: "right",
                      prop: "hours",
                      sortable: "",
                      width: "90",
                      "sort-method": _vm.sortResourceHours
                    }
                  }),
                  _vm._v(" "),
                  _c("vxe-table-column", {
                    attrs: {
                      title: "Days",
                      align: "right",
                      prop: "days",
                      sortable: "",
                      width: "90"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "resource_summary_edit",
          tabindex: "-1",
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c(
                    "vxe-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading"
                        }
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.resourceSummaryData, height: "430" }
                    },
                    [
                      _c("vxe-table-column", {
                        attrs: {
                          width: "350",
                          title: "Role Title",
                          prop: "resource_role"
                        }
                      }),
                      _vm._v(" "),
                      _c("vxe-table-column", {
                        attrs: {
                          width: "400",
                          title: "Talent Name",
                          prop: "talent_name"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "Select"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.checkAndOpenConfirmModal(
                                          scope.row.resource_role,
                                          scope.row.employee_id,
                                          scope.row
                                        )
                                      }
                                    },
                                    model: {
                                      value: scope.row.employee_id,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "employee_id", $$v)
                                      },
                                      expression: "scope.row.employee_id"
                                    }
                                  },
                                  _vm._l(_vm.employees, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" }
                  },
                  [_vm._v("Close")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.updateResourceSummary }
                  },
                  [_vm._v("Save changes")]
                )
              ])
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "confirm_modal",
          tabindex: "-1",
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v("\n          Are you sure you want to assign "),
                _c("strong", [_vm._v(_vm._s(_vm.selectedEmployeeName))]),
                _vm._v(" to the role of "),
                _c("strong", [_vm._v(_vm._s(_vm.selectedRole))]),
                _vm._v("? "),
                _c("br"),
                _vm._v(
                  "\n          If this role is not in the employee's current roles, it will be assigned to them.\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.confirmAssignment }
                  },
                  [_vm._v("Confirm")]
                )
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-portlet__head-caption" }, [
      _c("div", { staticClass: "m-portlet__head-title" }, [
        _c("h3", { staticClass: "m-portlet__head-text" }, [
          _vm._v("Resource Summary")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "m-portlet__nav" }, [
      _c("li", [
        _c(
          "a",
          {
            staticClass:
              "m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill",
            attrs: {
              href: "#",
              "data-toggle": "modal",
              "data-target": "#resource_summary_edit"
            }
          },
          [_c("i", { staticClass: "fa flaticon-edit" })]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
        [
          _c("i", { staticClass: "flaticon-edit" }),
          _vm._v(" Edit Resource Summary")
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "confirmModalLabel" } },
        [
          _c("i", { staticClass: "flaticon-edit" }),
          _vm._v(" Confirm Assignment")
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }