import { render, staticRenderFns } from "./accruals-summary.vue?vue&type=template&id=2844210a&"
import script from "./accruals-summary.js?vue&type=script&lang=js&"
export * from "./accruals-summary.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/web/bitool.softwaysolutions.com/htdocs/releases/20201012055253/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2844210a')) {
      api.createRecord('2844210a', component.options)
    } else {
      api.reload('2844210a', component.options)
    }
    module.hot.accept("./accruals-summary.vue?vue&type=template&id=2844210a&", function () {
      api.rerender('2844210a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend/project-plan/accruals-report/accruals-summary/accruals-summary.vue"
export default component.exports