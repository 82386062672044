var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-portlet__body" }, [
    _c("div", { staticClass: "m-section" }, [
      _c(
        "div",
        { staticClass: "m-section__content" },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c("metronic-table", {
            key: _vm.metronicTableKey,
            attrs: {
              tableData: _vm.projectListData,
              headers: _vm.headers,
              actionBtns: _vm.actionBtns,
              loader: _vm.loader
            }
          }),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }