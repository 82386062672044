var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "skill_set_dialog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "skill_set_dialog",
          attrs: { title: _vm.pageTitle, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.resetSkillSetForm
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "skillSetForm",
              attrs: {
                model: _vm.skillSetFormData,
                "label-position": "top",
                fullscreen: true,
                rules: _vm.skillsetFormRules
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el-form-item-override",
                        attrs: { label: "Skill name", prop: "skillName" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            key: _vm.counter,
                            staticClass: "select-box",
                            attrs: {
                              filterable: "",
                              disabled: _vm.formType == "edit",
                              "allow-create": _vm.allowCreateSkill,
                              "default-first-option": "",
                              placeholder: _vm.elSelectPlaceholder
                            },
                            model: {
                              value: _vm.skillSetFormData.skillName,
                              callback: function($$v) {
                                _vm.$set(_vm.skillSetFormData, "skillName", $$v)
                              },
                              expression: "skillSetFormData.skillName"
                            }
                          },
                          _vm._l(_vm.skillsetList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: _vm._f("capitalize")(item.skill_name),
                                value: item.skill_name,
                                disabled: item.disabled == 1
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el-form-item-override",
                        attrs: { label: "Notes", prop: "skillNote" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 5,
                            placeholder: _vm.elInputPlaceholder
                          },
                          model: {
                            value: _vm.skillSetFormData.skillNote,
                            callback: function($$v) {
                              _vm.$set(_vm.skillSetFormData, "skillNote", $$v)
                            },
                            expression: "skillSetFormData.skillNote"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetSkillSetForm()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addEmployeeSkill()
                    }
                  }
                },
                [_vm._v("Submit")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }