var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "m-nav-sticky", staticStyle: { "margin-top": "30px" } },
    [
      _c(
        "li",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.modify_permission == true,
              expression: "this.modify_permission == true"
            }
          ],
          staticClass: "m-nav-sticky__item",
          attrs: {
            "data-toggle": "m-tooltip",
            title: "",
            "data-placement": "left",
            "data-original-title": "Sync Project Plan"
          }
        },
        [
          _c(
            "a",
            {
              attrs: { "m-portlet-tool": "sync" },
              on: { click: _vm.syncProject }
            },
            [_c("i", { staticClass: "la la-refresh" })]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.active_page != "GanttCharts",
              expression: "this.active_page != 'GanttCharts'"
            }
          ],
          staticClass: "m-nav-sticky__item",
          attrs: {
            "data-toggle": "m-tooltip",
            title: "",
            "data-placement": "left",
            "data-original-title": "Gantt Charts"
          }
        },
        [
          _c("a", { attrs: { href: _vm.gantt_charts_url } }, [
            _c("i", { staticClass: "flaticon-interface-7" })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.active_page != "Dashboard",
              expression: "this.active_page != 'Dashboard'"
            }
          ],
          staticClass: "m-nav-sticky__item",
          attrs: {
            "data-toggle": "m-tooltip",
            title: "",
            "data-placement": "left",
            "data-original-title": "Dashboard"
          }
        },
        [
          _c("a", { attrs: { href: _vm.dashboard_url } }, [
            _c("i", { staticClass: "flaticon-squares-3" })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "m-nav-sticky__item",
          attrs: {
            "data-toggle": "m-tooltip",
            title: "",
            "data-placement": "left",
            "data-original-title": "Detailed Talent Forecast"
          }
        },
        [
          _c("a", { attrs: { href: _vm.talent_forecast_url } }, [
            _c("i", { staticClass: "flaticon-analytics" })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "m-nav-sticky__item",
          attrs: {
            "data-toggle": "m-tooltip",
            title: "",
            "data-placement": "left",
            "data-original-title": "Detailed Burn Report"
          }
        },
        [
          _c("a", { attrs: { href: _vm.burn_report_url } }, [
            _c("i", { staticClass: "flaticon-stopwatch" })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.jira_project_key,
              expression: "this.jira_project_key"
            }
          ],
          staticClass: "m-nav-sticky__item",
          attrs: {
            "data-toggle": "m-tooltip",
            title: "",
            "data-placement": "left",
            "data-original-title": "JIRA project"
          }
        },
        [
          _c(
            "a",
            {
              attrs: {
                href: _vm.jira_project_url,
                target: "_blank",
                disabled: "disabled"
              }
            },
            [_c("i", { staticClass: "la la-link" })]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "m-nav-sticky__item",
          attrs: {
            "data-toggle": "m-tooltip",
            title: "",
            "data-placement": "left",
            "data-original-title": "Go to plan"
          }
        },
        [
          _c("a", { attrs: { href: _vm.gsheet_url, target: "_blank" } }, [
            _c("i", { staticClass: "la la-external-link" })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "m-nav-sticky__item",
          attrs: {
            "data-toggle": "m-tooltip",
            title: "",
            "data-placement": "left",
            "data-original-title": "Planning"
          }
        },
        [
          _c("a", { attrs: { href: _vm.planning_url } }, [
            _c("i", { staticClass: "la la-clipboard" })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }