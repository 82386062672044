var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-content" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.accrualReportLoading,
                  expression: "accrualReportLoading"
                }
              ],
              staticClass: "m-portlet m-portlet--head-sm",
              attrs: { "m-portlet": "true" }
            },
            [
              _c("div", { staticClass: "m-portlet__head" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "m-portlet__head-tools" }, [
                  _c("ul", { staticClass: "m-portlet__nav" }, [
                    _c("li", { staticClass: "m-portlet__nav-item" }, [
                      _c(
                        "div",
                        { staticClass: "year-picker-selector" },
                        [
                          _c("el-year-picker", {
                            attrs: {
                              yearFormat: "yyyy",
                              selectedYear: _vm.selectedYear
                            },
                            on: {
                              "update:selectedYear": function($event) {
                                _vm.selectedYear = $event
                              },
                              "update:selected-year": function($event) {
                                _vm.selectedYear = $event
                              },
                              updatedYear: _vm.getAccrualReportByYear
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { ref: "converDivToPng", staticClass: "m-portlet__body" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-lg-12" },
                      [
                        _c("vxe-toolbar", {
                          scopedSlots: _vm._u([
                            {
                              key: "buttons",
                              fn: function() {
                                return [
                                  _c(
                                    "vxe-button",
                                    {
                                      attrs: { status: _vm.wipBtnStatus },
                                      on: {
                                        click: function($event) {
                                          return _vm.getPipelineReports("wip")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "WIP " +
                                          _vm._s(_vm.wipOpp && _vm.oppWip) +
                                          "\n                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "fa fa-angle-double-left mx-2",
                                    style: [
                                      _vm.wipPendingBtnStatus && {
                                        color: "#409eff"
                                      }
                                    ]
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "vxe-button",
                                    {
                                      attrs: {
                                        status: _vm.wipPendingBtnStatus
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.getPipelineReports(
                                            "Win pending"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Win Pending " +
                                          _vm._s(
                                            _vm.wipPendingOpp &&
                                              _vm.oppWinPending
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "fa fa-angle-double-left mx-2",
                                    style: [
                                      _vm.proposeBtnStatus && {
                                        color: "#409eff"
                                      }
                                    ]
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "vxe-button",
                                    {
                                      attrs: { status: _vm.proposeBtnStatus },
                                      on: {
                                        click: function($event) {
                                          return _vm.getPipelineReports(
                                            "Propose"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Propose " +
                                          _vm._s(
                                            _vm.proposeOpp && _vm.oppPropose
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "fa fa-angle-double-left mx-2",
                                    style: [
                                      _vm.developBtnStatus && {
                                        color: "#409eff"
                                      }
                                    ]
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "vxe-button",
                                    {
                                      attrs: { status: _vm.developBtnStatus },
                                      on: {
                                        click: function($event) {
                                          return _vm.getPipelineReports(
                                            "Develop"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Develop " +
                                          _vm._s(
                                            _vm.developOpp && _vm.oppDevelop
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "fa fa-angle-double-left mx-2",
                                    style: [
                                      _vm.qualifyBtnStatus && {
                                        color: "#409eff"
                                      }
                                    ]
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "vxe-button",
                                    {
                                      attrs: { status: _vm.qualifyBtnStatus },
                                      on: {
                                        click: function($event) {
                                          return _vm.getPipelineReports(
                                            "Qualify"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Qualify " +
                                          _vm._s(
                                            _vm.qualifyOpp && _vm.oppQualify
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-lg-12 custom-height-empty-placeholder"
                      },
                      [
                        _c("vxe-grid", {
                          ref: "exportAccrualDataCsv",
                          staticClass: "reverse-table",
                          attrs: {
                            border: "",
                            "show-overflow": "",
                            "cell-style": _vm.cellStyle,
                            "row-style": _vm.rowStyle,
                            "min-height": "200",
                            "show-header": false,
                            columns: _vm.accrualTableColumns,
                            data: _vm.currentStageTableData
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "m-alert m-alert--default alert" },
                          [
                            _vm._v("\n                  Total "),
                            _c("strong", [_vm._v(_vm._s(_vm.noOfOpp))]),
                            _vm._v(" opportunities in "),
                            _c("strong", [_vm._v(_vm._s(_vm.stageName))]),
                            _vm._v(" stage with a projection of  "),
                            _c("strong", [
                              _vm._v("$" + _vm._s(_vm.totalBudgetRevenue))
                            ]),
                            _vm._v(".\n              ")
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-12" }, [
                      _c(
                        "h3",
                        {
                          staticClass: "chart-header",
                          attrs: { "data-v-452a6430": "" }
                        },
                        [_vm._v("Cash Runway")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-12 custom-height-empty-placeholder"
                          },
                          [
                            _c("vxe-grid", {
                              ref: "exportCashRunwayDataCsv",
                              staticClass: "reverse-table",
                              attrs: {
                                border: "",
                                "show-overflow": "",
                                "row-style": _vm.rowStyle,
                                "show-header": false,
                                columns: _vm.cashRunwayColumns,
                                data: _vm.currentStageCashTableData
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("sidebar", [
        _c(
          "li",
          {
            staticClass: "m-nav-sticky__item",
            attrs: {
              "data-toggle": "m-tooltip",
              title: "Sync Hubspot deals & Cash data",
              "data-placement": "left",
              "data-original-title": "Sync Hubspot deals & Cash data"
            }
          },
          [
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    _vm.openSyncHubspotDeal = true
                  }
                }
              },
              [_c("i", { staticClass: "flaticon-refresh" })]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "m-nav-sticky__item",
            attrs: {
              "data-toggle": "m-tooltip",
              title: "Export accrual & Cash runway (.xlsx)",
              "data-placement": "left",
              "data-original-title": "Export accrual & Cash runway (.xlsx)"
            }
          },
          [
            _c("a", { on: { click: _vm.exportPipelineReports } }, [
              _c("i", { staticClass: "fa fa-file-excel" })
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("sync-hubspot-deal", {
        attrs: { open: _vm.openSyncHubspotDeal },
        on: {
          "update:open": function($event) {
            _vm.openSyncHubspotDeal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-portlet__head-caption" }, [
      _c("div", { staticClass: "m-portlet__head-title" }, [
        _c("span", { staticClass: "m-portlet__head-icon" }, [
          _c("i", { staticClass: "flaticon-graph" })
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "m-portlet__head-text" }, [
          _vm._v("Pipeline Impact on Accruals")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }