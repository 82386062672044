var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-6 col-sm-12" }, [
    _c(
      "div",
      { staticClass: "m-portlet tasks-panel", attrs: { "m-portlet": "true" } },
      [
        _c("div", { staticClass: "m-portlet__head" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "m-portlet__head-tools" }, [
            _c("ul", { staticClass: "m-portlet__nav" }, [
              _c("li", { staticClass: "m-portlet__nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill",
                    attrs: { "data-toggle": "m-tooltip", title: "Export" },
                    on: { click: _vm.exportTaskData }
                  },
                  [_c("i", { staticClass: "fa fa-file-excel" })]
                )
              ]),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass:
                    "m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push",
                  attrs: {
                    id: "tasks-quick-links",
                    "m-dropdown-toggle": "hover",
                    "aria-expanded": "true"
                  }
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "m-dropdown__wrapper",
                      staticStyle: { "z-index": "101" }
                    },
                    [
                      _c("span", {
                        staticClass:
                          "m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust",
                        staticStyle: { left: "auto", right: "21.5px" }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "m-dropdown__inner" }, [
                        _c("div", { staticClass: "m-dropdown__body" }, [
                          _c("div", { staticClass: "m-dropdown__content" }, [
                            _c("ul", { staticClass: "m-nav" }, [
                              _vm._m(2),
                              _vm._v(" "),
                              _c("li", { staticClass: "m-nav__item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "m-nav__link",
                                    attrs: { href: _vm.project_gantt_url }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "m-nav__link-icon  flaticon-interface-7"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "m-nav__link-text" },
                                      [_vm._v("Project gantt chart")]
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "m-nav__item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "m-nav__link",
                                    attrs: { href: _vm.resource_gantt_url }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "m-nav__link-icon flaticon-graphic-2"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "m-nav__link-text" },
                                      [_vm._v("Resource gantt chart")]
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ])
                      ])
                    ]
                  )
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "m-portlet__body" }, [
          _c(
            "div",
            { staticClass: "table-responsive tasks-table" },
            [
              _c("vxe-grid", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                ref: "taskTable",
                attrs: {
                  border: "",
                  stripe: "",
                  height: "250",
                  columns: _vm.taskHeaders,
                  data: _vm.tasks_data,
                  "highlight-current-row": "",
                  "highlight-hover-row": "",
                  "show-overflow": "",
                  size: "mini",
                  resizable: "",
                  toolbar: _vm.tableToolbar
                },
                scopedSlots: _vm._u([
                  {
                    key: "buttons",
                    fn: function() {
                      return [
                        _c("vxe-button", { attrs: { type: "text" } }, [
                          _vm._v("Tasks Breakdown")
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "toolbar_buttons",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  },
                  {
                    key: "empty",
                    fn: function() {
                      return [_c("span", [_c("p", [_vm._v("No Data")])])]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-portlet__head-caption" }, [
      _c("div", { staticClass: "m-portlet__head-title" }, [
        _c("h3", { staticClass: "m-portlet__head-text" }, [
          _vm._v("\n            Tasks\n          ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "m-portlet__nav-link btn btn-secondary  m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill m-dropdown__toggle"
      },
      [_c("i", { staticClass: "la la-ellipsis-v" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "m-nav__section m-nav__section--first" }, [
      _c("span", { staticClass: "m-nav__section-text" }, [
        _vm._v("Quick Actions")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }