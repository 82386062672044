import { render, staticRenderFns } from "./talent-forecast-report.vue?vue&type=template&id=3ef5c389&"
import script from "./talent-forecast-report.js?vue&type=script&lang=js&"
export * from "./talent-forecast-report.js?vue&type=script&lang=js&"
import style0 from "./talent-forecast-report.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/web/bitool.softwaysolutions.com/htdocs/releases/20201012055253/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ef5c389')) {
      api.createRecord('3ef5c389', component.options)
    } else {
      api.reload('3ef5c389', component.options)
    }
    module.hot.accept("./talent-forecast-report.vue?vue&type=template&id=3ef5c389&", function () {
      api.rerender('3ef5c389', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend/project-plan/talent-forecast/talent-forecast-report/talent-forecast-report.vue"
export default component.exports