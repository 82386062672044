var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-6 col-sm-12" }, [
    _c(
      "div",
      { staticClass: "m-portlet m-portlet--tabs m-portlet--full-height" },
      [
        _c("div", { staticClass: "m-portlet__head" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "m-portlet__head-tools" }, [
            _c("ul", { staticClass: "m-portlet__nav" }, [
              _c(
                "li",
                {
                  staticClass:
                    "m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push",
                  attrs: { "m-dropdown-toggle": "hover" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "m-portlet__nav-link m-dropdown__toggle dropdown-toggle btn m-btn--sm m-btn--pill btn-secondary m-btn m-btn--label-brand m--font-brand"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.selected_dropdown) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-dropdown__wrapper" }, [
                    _c("span", {
                      staticClass:
                        "m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "m-dropdown__inner" }, [
                      _c("div", { staticClass: "m-dropdown__body" }, [
                        _c("div", { staticClass: "m-dropdown__content" }, [
                          _c(
                            "ul",
                            { staticClass: "m-nav" },
                            _vm._l(_vm.options, function(option) {
                              return _c("li", { staticClass: "m-nav__item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "m-nav__link",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function($event) {
                                        return _vm.changeInterval(option.value)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "m-nav__link-text" },
                                      [_vm._v(_vm._s(option.label))]
                                    )
                                  ]
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _vm._m(1)
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "m-portlet__body" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "col-md-8" }, [
                _c(
                  "div",
                  { staticClass: "table-responsive" },
                  [
                    _c(
                      "vxe-table",
                      {
                        attrs: {
                          border: "",
                          stripe: "",
                          height: "250",
                          "highlight-current-row": "",
                          "highlight-hover-row": "",
                          size: "mini",
                          data: _vm.accruals_data
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "empty",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { color: "black" } },
                                  [_c("p", [_vm._v("No Data")])]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("vxe-table-column", {
                          attrs: {
                            prop: "date",
                            align: "right",
                            title: _vm.accrual_recurrence
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", {
                                    class: _vm.valueBasedFontClass(
                                      scope.row.date
                                    )
                                  }),
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("bit_date_format")(scope.row.date)
                                    ) + "\n              "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("vxe-table-column", {
                          attrs: {
                            prop: "hours",
                            align: "right",
                            title: "Hours",
                            formatter: _vm.roundOffHours
                          }
                        }),
                        _vm._v(" "),
                        _c("vxe-table-column", {
                          attrs: {
                            title: "Accrued",
                            align: "right",
                            prop: "accrual",
                            formatter: _vm.roundOffAccruals
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("accruals-summary-widget", {
                attrs: {
                  project_id: _vm.project_id,
                  total_hours: _vm.total_hours,
                  total_accrual: _vm.total_accrual
                }
              })
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-portlet__head-caption" }, [
      _c("div", { staticClass: "m-portlet__head-title" }, [
        _c("h3", { staticClass: "m-portlet__head-text" }, [
          _vm._v("\n          Forecasted Accruals\n        ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          staticClass:
            "m-portlet__nav-link btn btn-secondary m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill",
          attrs: {
            "data-toggle": "m-tooltip",
            title: "Copy accruals data",
            "data-clipboard": "true",
            "data-clipboard-target": "#accuralsTable"
          }
        },
        [_c("i", { staticClass: "fa fa-copy" })]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }