var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-content" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c(
          "div",
          {
            staticClass: "m-portlet m-portlet--head-sm",
            attrs: { "m-portlet": "true" }
          },
          [
            _c("div", { staticClass: "m-portlet__head" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "m-portlet__head-tools" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn-group btn-group-sm push-10-r scope-search"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn m-btn btn-outline-info btn-sm active",
                        on: { click: _vm.exportDataEvent }
                      },
                      [_vm._v("Export")]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "m-portlet__body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Skills")
                      ]),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            filterable: "",
                            "collapse-tags": "",
                            placeholder: "Skills",
                            disabled: _vm.loading
                          },
                          on: {
                            change: function($event) {
                              return _vm.loadEmployeeReportBySkill()
                            }
                          },
                          model: {
                            value: _vm.selectedSkills,
                            callback: function($$v) {
                              _vm.selectedSkills = $$v
                            },
                            expression: "selectedSkills"
                          }
                        },
                        _vm._l(_vm.skills, function(skill) {
                          return _c("el-option", {
                            key: skill.value,
                            attrs: { label: skill.label, value: skill.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Date range")
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "fa fa-info-circle font-blue push-2-l",
                        attrs: {
                          "data-toggle": "m-tooltip",
                          "data-placement": "left",
                          title:
                            "Selected dates are auto adjusted to the beginning & end of the week/month."
                        }
                      }),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "To",
                          "start-placeholder": "Start date",
                          "end-placeholder": "End date",
                          format: "MM-dd-yyyy",
                          clearable: false,
                          "picker-options": _vm.datePickerOptions,
                          disabled: _vm.loading
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadEmployeeReportBySkill()
                          }
                        },
                        model: {
                          value: _vm.selectedDateRange,
                          callback: function($$v) {
                            _vm.selectedDateRange = $$v
                          },
                          expression: "selectedDateRange"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Pool")
                      ]),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            "collapse-tags": "",
                            placeholder: "Pool",
                            disabled: _vm.loading
                          },
                          on: {
                            change: function($event) {
                              return _vm.loadEmployeeReportBySkill()
                            }
                          },
                          model: {
                            value: _vm.selectedPool,
                            callback: function($$v) {
                              _vm.selectedPool = $$v
                            },
                            expression: "selectedPool"
                          }
                        },
                        _vm._l(_vm.poolOptions, function(pool) {
                          return _c("el-option", {
                            key: pool.id,
                            attrs: { label: pool.name, value: pool.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: {
                          "tab-click": function($event) {
                            return _vm.loadEmployeeReportBySkill()
                          }
                        },
                        model: {
                          value: _vm.recurrence,
                          callback: function($$v) {
                            _vm.recurrence = $$v
                          },
                          expression: "recurrence"
                        }
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: {
                            label: "Daily Report",
                            id: "daily",
                            name: "daily"
                          }
                        }),
                        _vm._v(" "),
                        _c("el-tab-pane", {
                          attrs: {
                            label: "Weekly Report",
                            id: "weekly",
                            name: "weekly"
                          }
                        }),
                        _vm._v(" "),
                        _c("el-tab-pane", {
                          attrs: {
                            label: "Monthly Report",
                            id: "monthly",
                            name: "monthly"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "forecast-chart-controls clearfix pull-right push-10-t"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "bicheck-inline forecast-bicheck" },
                          [
                            _c(
                              "vxe-radio",
                              {
                                attrs: { name: "xtabs", label: "available" },
                                model: {
                                  value: _vm.tabSelected,
                                  callback: function($$v) {
                                    _vm.tabSelected = $$v
                                  },
                                  expression: "tabSelected"
                                }
                              },
                              [_vm._v("Available")]
                            ),
                            _vm._v(" "),
                            _c(
                              "vxe-radio",
                              {
                                attrs: { name: "xtabs", label: "planned" },
                                model: {
                                  value: _vm.tabSelected,
                                  callback: function($$v) {
                                    _vm.tabSelected = $$v
                                  },
                                  expression: "tabSelected"
                                }
                              },
                              [_vm._v("Planned")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tabSelected === "available",
                            expression: "tabSelected === 'available'"
                          }
                        ]
                      },
                      [
                        _c(
                          "vxe-table",
                          {
                            ref: "availableTable",
                            attrs: {
                              border: "",
                              resizable: "",
                              "show-header-overflow": "",
                              "show-overflow": "",
                              "highlight-hover-row": "",
                              loading: _vm.loading,
                              data: _vm.formatemployeeListBySkill,
                              "sync-resize": _vm.tabSelected
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "empty",
                                fn: function() {
                                  return [_c("p", [_vm._v("No Data")])]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [
                            _c("vxe-table-column", {
                              attrs: {
                                field: "name",
                                type: "html",
                                title: "Name",
                                "min-width": "150",
                                fixed: "left",
                                sortable: "",
                                "sort-method": _vm.sortNameMethod,
                                formatter: _vm.formatRole
                              }
                            }),
                            _vm._v(" "),
                            _vm._l(_vm.headers, function(header, index) {
                              return _c("vxe-table-column", {
                                key: index,
                                attrs: {
                                  field: header,
                                  title: header,
                                  "min-width": "130"
                                }
                              })
                            }),
                            _vm._v(" "),
                            _vm.selectedSkills.length > 1
                              ? _c("vxe-table-column", {
                                  attrs: {
                                    field: "Skills",
                                    type: "html",
                                    title: "Skills",
                                    "min-width": "150",
                                    fixed: "left",
                                    "show-overflow": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return _vm._l(
                                            scope.row.skills,
                                            function(skill, index) {
                                              return _c("div", { key: index }, [
                                                _vm._v(_vm._s(skill))
                                              ])
                                            }
                                          )
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1881013710
                                  )
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("vxe-table-column", {
                              attrs: {
                                field: "action",
                                type: "html",
                                title: "Action",
                                "min-width": "80",
                                fixed: "right"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-sm btn-brand",
                                          attrs: {
                                            type: "button",
                                            "data-toggle": "modal",
                                            "data-target": "#available-talents",
                                            "aria-haspopup": "true",
                                            "aria-expanded": "false"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadProjectDetails(
                                                scope.row.id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Info")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "modal fade",
                            staticStyle: { display: "none" },
                            attrs: {
                              id: "available-talents",
                              tabindex: "-1",
                              role: "dialog",
                              "aria-labelledby": "available-talents-modal",
                              "aria-hidden": "true"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "modal-dialog",
                                attrs: { role: "document" }
                              },
                              [
                                _c("div", { staticClass: "modal-content" }, [
                                  _vm._m(1),
                                  _vm._v(" "),
                                  _vm.projects.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.modalLoading,
                                              expression: "modalLoading"
                                            }
                                          ],
                                          staticClass: "modal-body",
                                          staticStyle: {
                                            "max-height": "530px",
                                            overflow: "scroll"
                                          }
                                        },
                                        _vm._l(_vm.projects, function(
                                          item,
                                          index
                                        ) {
                                          return _c("div", { key: index }, [
                                            _c("h5", [
                                              _vm._v(_vm._s(item.name))
                                            ]),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("strong", [_vm._v("Role :")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.project_role) +
                                                  "\n                          "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("strong", [
                                                _vm._v("End Date :")
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("bit_date_format")(
                                                      item.end_date
                                                    )
                                                  ) +
                                                  "\n                          "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("strong", [_vm._v("Team :")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.teams) +
                                                  "\n                          "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("hr")
                                          ])
                                        }),
                                        0
                                      )
                                    : _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.modalLoading,
                                              expression: "modalLoading"
                                            }
                                          ],
                                          staticClass: "modal-body"
                                        },
                                        [
                                          _vm._v(
                                            " Currently not assigned to any projects"
                                          )
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _vm._m(2)
                                ])
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tabSelected === "planned",
                            expression: "tabSelected === 'planned'"
                          }
                        ]
                      },
                      [
                        _c(
                          "vxe-table",
                          {
                            ref: "plannedTable",
                            attrs: {
                              border: "",
                              resizable: "",
                              "show-header-overflow": "",
                              "show-overflow": "",
                              "highlight-hover-row": "",
                              loading: _vm.loading,
                              data: _vm.formatemployeeListBySkill,
                              "sync-resize": _vm.tabSelected
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "empty",
                                fn: function() {
                                  return [_c("p", [_vm._v("No Data")])]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [
                            _c("vxe-table-column", {
                              attrs: {
                                field: "name",
                                type: "html",
                                title: "Name",
                                "min-width": "150",
                                fixed: "left",
                                sortable: "",
                                "sort-method": _vm.sortNameMethod,
                                formatter: _vm.formatRole
                              }
                            }),
                            _vm._v(" "),
                            _vm._l(_vm.headers, function(header, index) {
                              return _c("vxe-table-column", {
                                key: index,
                                attrs: {
                                  field: header,
                                  title: header,
                                  "min-width": "130"
                                }
                              })
                            }),
                            _vm._v(" "),
                            _vm.selectedSkills.length > 1
                              ? _c("vxe-table-column", {
                                  attrs: {
                                    field: "Skills",
                                    type: "html",
                                    title: "Skills",
                                    "min-width": "150",
                                    fixed: "left",
                                    "show-overflow": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return _vm._l(
                                            scope.row.skills,
                                            function(skill, index) {
                                              return _c("div", { key: index }, [
                                                _vm._v(_vm._s(skill))
                                              ])
                                            }
                                          )
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1881013710
                                  )
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("vxe-table-column", {
                              attrs: {
                                field: "action",
                                type: "html",
                                title: "Action",
                                "min-width": "80",
                                fixed: "right"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-sm btn-brand",
                                          attrs: {
                                            type: "button",
                                            "data-toggle": "modal",
                                            "data-target": "#planned-talents",
                                            "aria-haspopup": "true",
                                            "aria-expanded": "false"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadProjectDetails(
                                                scope.row.id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Info")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "modal fade",
                            staticStyle: { display: "none" },
                            attrs: {
                              id: "planned-talents",
                              tabindex: "-1",
                              role: "dialog",
                              "aria-labelledby": "planned-talents-modal",
                              "aria-hidden": "true"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "modal-dialog",
                                attrs: { role: "document" }
                              },
                              [
                                _c("div", { staticClass: "modal-content" }, [
                                  _vm._m(3),
                                  _vm._v(" "),
                                  _vm.projects.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.modalLoading,
                                              expression: "modalLoading"
                                            }
                                          ],
                                          staticClass: "modal-body",
                                          staticStyle: {
                                            "max-height": "530px",
                                            overflow: "scroll"
                                          }
                                        },
                                        _vm._l(_vm.projects, function(
                                          item,
                                          index
                                        ) {
                                          return _c("div", { key: index }, [
                                            _c("h5", [
                                              _vm._v(_vm._s(item.name))
                                            ]),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("strong", [_vm._v("Role :")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.project_role) +
                                                  "\n                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("strong", [
                                                _vm._v("End Date :")
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("bit_date_format")(
                                                      item.end_date
                                                    )
                                                  ) +
                                                  "\n                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("p", [
                                              _c("strong", [_vm._v("Team :")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.teams) +
                                                  "\n                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("hr")
                                          ])
                                        }),
                                        0
                                      )
                                    : _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.modalLoading,
                                              expression: "modalLoading"
                                            }
                                          ],
                                          staticClass: "modal-body"
                                        },
                                        [
                                          _vm._v(
                                            " Currently not assigned to any projects"
                                          )
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _vm._m(4)
                                ])
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("vxe-pager", {
                      attrs: {
                        background: "",
                        "current-page": _vm.pageInfo.currentPage,
                        "page-size": _vm.pageInfo.pageSize,
                        total: _vm.pageInfo.totalResult,
                        perfect: true,
                        "auto-hidden": true,
                        layouts: [
                          "PrevJump",
                          "PrevPage",
                          "JumpNumber",
                          "NextPage",
                          "NextJump",
                          "Sizes",
                          "FullJump",
                          "Total"
                        ]
                      },
                      on: {
                        "update:currentPage": function($event) {
                          return _vm.$set(_vm.pageInfo, "currentPage", $event)
                        },
                        "update:current-page": function($event) {
                          return _vm.$set(_vm.pageInfo, "currentPage", $event)
                        },
                        "update:pageSize": function($event) {
                          return _vm.$set(_vm.pageInfo, "pageSize", $event)
                        },
                        "update:page-size": function($event) {
                          return _vm.$set(_vm.pageInfo, "pageSize", $event)
                        },
                        "page-change": _vm.getEmployeeListByPage
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-portlet__head-caption" }, [
      _c("div", { staticClass: "m-portlet__head-title" }, [
        _c("span", { staticClass: "m-portlet__head-icon" }, [
          _c("i", { staticClass: "flaticon-analytics" })
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "m-portlet__head-text" }, [
          _vm._v("Talent Skill Analysis")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        {
          staticClass: "modal-title",
          attrs: { id: "available-talents-modal" }
        },
        [_vm._v("Project(s) Info")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("Close")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "planned-talents-modal" } },
        [_vm._v("Project(s) Info")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("Close")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }