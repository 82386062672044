import { render, staticRenderFns } from "./project-accrual-report.vue?vue&type=template&id=5dc8f550&scoped=true&"
import script from "./project-accrual-report.js?vue&type=script&lang=js&"
export * from "./project-accrual-report.js?vue&type=script&lang=js&"
import style0 from "./project-accrual-report.css?vue&type=style&index=0&id=5dc8f550&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dc8f550",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/web/bitool.softwaysolutions.com/htdocs/releases/20201012055253/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5dc8f550')) {
      api.createRecord('5dc8f550', component.options)
    } else {
      api.reload('5dc8f550', component.options)
    }
    module.hot.accept("./project-accrual-report.vue?vue&type=template&id=5dc8f550&scoped=true&", function () {
      api.rerender('5dc8f550', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend/finance/accruals/project-accrual-report/project-accrual-report.vue"
export default component.exports