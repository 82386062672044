var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-subheader " }, [
    _c("div", { staticClass: "d-flex align-items-center" }, [
      _c("div", { staticClass: "mr-auto" }, [
        _c(
          "h3",
          { staticClass: "m-subheader__title m-subheader__title--separator" },
          [_vm._v(_vm._s(_vm.breadcrumbRootPage))]
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "m-subheader__breadcrumbs m-nav m-nav--inline" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.data, function(item) {
              return [
                _c("li", { staticClass: "m-nav__separator padding-unset" }, [
                  _vm._v("-")
                ]),
                _vm._v(" "),
                _c(
                  "li",
                  { key: item.label, staticClass: "m-nav__item" },
                  [
                    !item.isHref
                      ? _c(
                          "router-link",
                          {
                            staticClass: "m-nav__link",
                            attrs: { to: { path: item.href } }
                          },
                          [
                            _c("span", { staticClass: "m-nav__link-text" }, [
                              _vm._v(_vm._s(item.label))
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.isHref
                      ? _c(
                          "a",
                          {
                            staticClass: "m-nav__link",
                            attrs: { href: item.href }
                          },
                          [
                            _c("span", { staticClass: "m-nav__link-text" }, [
                              _vm._v(_vm._s(item.label))
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "m-nav__item m-nav__item--home" }, [
      _c("a", { staticClass: "m-nav__link", attrs: { href: "/" } }, [
        _c("i", { staticClass: "m-nav__link-icon la la-home" })
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "divider" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }