var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push",
        attrs: { "m-dropdown-toggle": "hover", "aria-expanded": "true" }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "m-dropdown__wrapper",
            staticStyle: { "z-index": "101" }
          },
          [
            _c("span", {
              staticClass:
                "m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust",
              staticStyle: { left: "auto", right: "21.5px" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "m-dropdown__inner" }, [
              _c("div", { staticClass: "m-dropdown__body" }, [
                _c("div", { staticClass: "m-dropdown__content" }, [
                  _c(
                    "ul",
                    { staticClass: "m-nav" },
                    [_vm._m(1), _vm._v(" "), _vm._t("default")],
                    2
                  )
                ])
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "m-portlet__nav-link btn btn-lg btn-secondary m-btn m-btn--outline-2x m-btn--air m-btn--icon m-btn--icon-only m-btn--pill m-dropdown__toggle",
        attrs: { href: "#" }
      },
      [
        _c("i", { staticClass: "la la-plus m--hide" }),
        _vm._v(" "),
        _c("i", { staticClass: "la la-ellipsis-h" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      { staticClass: "m-nav__section m-nav__section--first m--hide" },
      [
        _c("span", { staticClass: "m-nav__section-text" }, [
          _vm._v("Quick Actions")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }