var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "m-accordion m-accordion--default m-accordion--toggle-arrow m--font-brand mt-lg-2",
      attrs: { id: "project_summary_panel", role: "tablist" }
    },
    [
      _c("div", { staticClass: "m-accordion__item" }, [
        _c(
          "div",
          {
            staticClass: "m-accordion__item-head collapsed",
            attrs: {
              srole: "tab",
              id: "project_summary_panel_head",
              "data-toggle": "collapse",
              href: "#project_summary_panel_body",
              "aria-expanded": "  false"
            }
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("span", { staticClass: "m-accordion__item-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.projectSummary.project_name) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "m-accordion__item-mode" })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "m-accordion__item-body collapse project_summary_panel",
            attrs: {
              id: "project_summary_panel_body",
              role: "tabpanel",
              "aria-labelledby": "project_summary_panel_head",
              "data-parent": "#project_summary_panel"
            }
          },
          [
            _c("div", { staticClass: "m-accordion__item-content" }, [
              _c("div", { staticClass: "m-widget28" }, [
                _c("div", { staticClass: "m-widget28__container" }, [
                  _c("div", { staticClass: "m-widget28__tab mt-0" }, [
                    _c("div", { staticClass: "m-widget28__tab-container" }, [
                      _c("div", { staticClass: "m-widget28__tab-items" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Client Name: ")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.projectSummary.client_name ||
                                      "Not Available"
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Acc. Manager: ")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                [
                                  _vm._l(
                                    _vm.projectSummary.account_manager,
                                    function(accManager, index) {
                                      return [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(accManager.name) +
                                            _vm._s(
                                              index ==
                                                _vm.projectSummary
                                                  .account_manager.length -
                                                  1
                                                ? ""
                                                : ","
                                            ) +
                                            "\n                          "
                                        )
                                      ]
                                    }
                                  )
                                ],
                                2
                              ),
                              _vm._v(" "),
                              !_vm.projectSummary.account_manager.length
                                ? _c("span", [_vm._v("Not Available")])
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Proj. Manager:")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                [
                                  _vm._l(
                                    _vm.projectSummary.project_manager,
                                    function(projectManager, index) {
                                      return [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(projectManager.name) +
                                            _vm._s(
                                              index ===
                                                _vm.projectSummary
                                                  .project_manager.length -
                                                  1
                                                ? ""
                                                : ", "
                                            ) +
                                            "\n                          "
                                        )
                                      ]
                                    }
                                  )
                                ],
                                2
                              ),
                              _vm._v(" "),
                              !_vm.projectSummary.project_manager.length
                                ? _c("span", [_vm._v("Not Available")])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Jira code: ")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.projectSummary.jira_code ||
                                      "Not Available"
                                  )
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Contract Type: ")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.projectSummary.contract_type ||
                                      "Not Available"
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Contract Range: ")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDateObj")(
                                      _vm.projectSummary.start_date,
                                      "MMM YYYY"
                                    )
                                  ) +
                                    " - " +
                                    _vm._s(
                                      _vm._f("formatDateObj")(
                                        _vm.projectSummary.end_date,
                                        "MMM YYYY"
                                      )
                                    )
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Budgeted Revenue: ")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.projectSummary.budgeted_revenue
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-widget28__tab-item" }, [
                              _c("span", [_vm._v("Accrual Status: ")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.projectSummary.status
                                      ? "Active"
                                      : "Inactive"
                                  )
                                )
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "m-accordion__item-icon" }, [
      _c("i", { staticClass: "flaticon-information" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }