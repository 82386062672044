var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project_accrual_create" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "project_accrual_form",
          attrs: { title: _vm.pageTitle, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.resetProjectAccrualForm
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "projectAccrualForm",
              attrs: {
                model: _vm.projectAccrualFormData,
                "label-position": "top",
                fullscreen: true,
                rules: _vm.projectAccrualFormRules
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el-form-item-override",
                        attrs: {
                          label: "Project Name",
                          "label-width": _vm.formLabelWidth,
                          prop: "name"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { autocomplete: "off" },
                          model: {
                            value: _vm.projectAccrualFormData.name,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.projectAccrualFormData,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "projectAccrualFormData.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el-form-item-override",
                        attrs: {
                          label: "Contract Type",
                          "label-width": _vm.formLabelWidth,
                          prop: "contract_type"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "Select" },
                            model: {
                              value: _vm.projectAccrualFormData.contract_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.projectAccrualFormData,
                                  "contract_type",
                                  $$v
                                )
                              },
                              expression: "projectAccrualFormData.contract_type"
                            }
                          },
                          _vm._l(_vm.contractTypeSelectOptions, function(type) {
                            return _c("el-option", {
                              key: type.id,
                              attrs: { label: type.name, value: type.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el-form-item-override",
                        attrs: {
                          label: "Project Manager",
                          "label-width": _vm.formLabelWidth,
                          prop: "project_manager_id"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "", placeholder: "Select" },
                            model: {
                              value:
                                _vm.projectAccrualFormData.project_manager_id,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.projectAccrualFormData,
                                  "project_manager_id",
                                  $$v
                                )
                              },
                              expression:
                                "projectAccrualFormData.project_manager_id"
                            }
                          },
                          _vm._l(_vm.employeeSelectOptions, function(pm) {
                            return _c("el-option", {
                              key: pm.id,
                              attrs: { label: pm.name, value: pm.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el-form-item-override",
                        attrs: {
                          label: "Account Manager",
                          "label-width": _vm.formLabelWidth,
                          prop: "account_manager_id"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "", placeholder: "Select" },
                            model: {
                              value:
                                _vm.projectAccrualFormData.account_manager_id,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.projectAccrualFormData,
                                  "account_manager_id",
                                  $$v
                                )
                              },
                              expression:
                                "projectAccrualFormData.account_manager_id"
                            }
                          },
                          _vm._l(_vm.employeeSelectOptions, function(am) {
                            return _c("el-option", {
                              key: am.id,
                              attrs: { label: am.name, value: am.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el-form-item-override",
                        attrs: {
                          label: "Project Plan",
                          "label-width": _vm.formLabelWidth,
                          prop: "estimate_id"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              placeholder: "Select",
                              clearable: "",
                              readonly: _vm.disableField,
                              disabled: _vm.disableField
                            },
                            on: {
                              change: function($event) {
                                return _vm.getProjectDates()
                              }
                            },
                            model: {
                              value: _vm.projectAccrualFormData.estimate_id,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.projectAccrualFormData,
                                  "estimate_id",
                                  $$v
                                )
                              },
                              expression: "projectAccrualFormData.estimate_id"
                            }
                          },
                          _vm._l(_vm.projectPlanSelectOptions, function(
                            projectPlan
                          ) {
                            return _c("el-option", {
                              key: projectPlan.id,
                              attrs: {
                                label: projectPlan.name,
                                value: projectPlan.id
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el-form-item-override",
                        attrs: {
                          label: "Budgeted Revenue",
                          "label-width": _vm.formLabelWidth,
                          prop: "budgeted_revenue"
                        }
                      },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "currency",
                              rawName: "v-currency",
                              value: {
                                currency: "USD",
                                locale: "en-US",
                                "auto-decimal-mode": true
                              },
                              expression:
                                "{currency: 'USD', locale: 'en-US','auto-decimal-mode':true}"
                            }
                          ],
                          attrs: {
                            type: "text",
                            placeholder: "Enter a Budgeted revenue",
                            "trigger-on-focus": false,
                            min: 0.0,
                            label: "Budgeted",
                            autocomplete: "off"
                          },
                          nativeOn: {
                            keyup: function($event) {
                              return _vm.checkForMaxValue(
                                _vm.projectAccrualFormData.budgeted_revenue,
                                $event
                              )
                            }
                          },
                          model: {
                            value: _vm.projectAccrualFormData.budgeted_revenue,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.projectAccrualFormData,
                                "budgeted_revenue",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "projectAccrualFormData.budgeted_revenue"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el-form-item-override",
                        attrs: {
                          label: "Jira Code",
                          "label-width": _vm.formLabelWidth,
                          prop: "jira_code"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              "allow-create": "",
                              "default-first-option": "",
                              clearable: "",
                              placeholder: "Please select a Jira project code"
                            },
                            model: {
                              value: _vm.projectAccrualFormData.jira_code,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.projectAccrualFormData,
                                  "jira_code",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "projectAccrualFormData.jira_code"
                            }
                          },
                          _vm._l(_vm.projectKeyList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.text, value: item.id }
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("If your project is missing,")]),
                        _vm._v(" "),
                        _c("el-button", {
                          attrs: { type: "text" },
                          domProps: { innerHTML: _vm._s(_vm.syncButtonHtml) },
                          on: { click: _vm.syncJiraProjectCodes }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("from Jira.")])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el-form-item-override",
                        attrs: {
                          label: "Company Department",
                          "label-width": _vm.formLabelWidth,
                          prop: "company_department_id"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "", placeholder: "Select" },
                            model: {
                              value:
                                _vm.projectAccrualFormData
                                  .company_department_id,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.projectAccrualFormData,
                                  "company_department_id",
                                  $$v
                                )
                              },
                              expression:
                                "projectAccrualFormData.company_department_id"
                            }
                          },
                          _vm._l(_vm.companyDeptOptions, function(dept) {
                            return _c("el-option", {
                              key: dept.id,
                              attrs: { label: dept.name, value: dept.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el-form-item-override",
                        attrs: {
                          label: "Start Date",
                          "label-width": _vm.formLabelWidth,
                          prop: "start_date"
                        }
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "Pick start date",
                            readonly: _vm.disableField || _vm.dateDisable,
                            disabled: _vm.disableField || _vm.dateDisable,
                            format: "MM-dd-yyyy"
                          },
                          model: {
                            value: _vm.projectAccrualFormData.start_date,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.projectAccrualFormData,
                                "start_date",
                                $$v
                              )
                            },
                            expression: "projectAccrualFormData.start_date"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "el-form-item-override",
                        attrs: {
                          label: "End Date",
                          "label-width": _vm.formLabelWidth,
                          prop: "end_date"
                        }
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            "picker-options": _vm.endDatePickerOptions,
                            placeholder: "Pick end date",
                            format: "MM-dd-yyyy",
                            readonly: _vm.dateDisable,
                            disabled: _vm.dateDisable,
                            "validate-event": true
                          },
                          model: {
                            value: _vm.projectAccrualFormData.end_date,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.projectAccrualFormData,
                                "end_date",
                                $$v
                              )
                            },
                            expression: "projectAccrualFormData.end_date"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetProjectAccrualForm()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              !_vm.loading
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.createProjectAccrual()
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  )
                : _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v("Loading")
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }