var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("breadcrumb", {
              attrs: {
                data: _vm.breadcrumbOptions,
                breadcrumbRootPage: "Accruals Dashboard"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6 pm-selector pm-s" },
          [
            _vm.allowCreate
              ? _c(
                  "el-button",
                  {
                    staticClass: "metronic-btn",
                    attrs: { type: "primary", round: "" },
                    on: {
                      click: function($event) {
                        _vm.openProjectAccrualCreate = true
                      }
                    }
                  },
                  [_vm._v("Create Accrual")]
                )
              : _vm._e(),
            _vm._v(" "),
            this.isCardLayout
              ? _c("el-select-wrapper", {
                  attrs: {
                    options: _vm.formatedProjectManagerFilterOptions,
                    selected: _vm.selectedPM,
                    placeholder: "Select Project Manager"
                  },
                  on: {
                    "update:selected": function($event) {
                      _vm.selectedPM = $event
                    },
                    update: _vm.loadReport
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-content" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", { staticClass: "m-portlet m-portlet--head-sm" }, [
                _c("div", { staticClass: "m-portlet__head" }, [
                  _c("div", { staticClass: "m-portlet__head-caption" }, [
                    _c("div", { staticClass: "m-portlet__head-title" }, [
                      _c("h3", { staticClass: "m-portlet__head-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.selectedPM && _vm.selectedPM.id == -1
                              ? "All PM's Accrual Summary"
                              : _vm.selectedPM &&
                                  _vm.selectedPM.name + "'s Accrual Summary"
                          )
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-portlet__head-tools" }, [
                    _c("ul", { staticClass: "m-portlet__nav" }, [
                      _vm.isCardLayout
                        ? _c(
                            "li",
                            { staticClass: "m-portlet__nav-item" },
                            [
                              _c("el-month-picker", {
                                staticClass: "project-month-picker",
                                attrs: {
                                  clearable: false,
                                  monthFormat: "MMM yyyy",
                                  selectedMonth: _vm.selectedMonth
                                },
                                on: {
                                  "update:selectedMonth": function($event) {
                                    _vm.selectedMonth = $event
                                  },
                                  "update:selected-month": function($event) {
                                    _vm.selectedMonth = $event
                                  },
                                  updatedMonth: function($event) {
                                    return _vm.loadProjectSummaryData()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", { staticClass: "m-portlet__nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn-group btn-group-sm",
                            attrs: {
                              role: "group",
                              "aria-label": "Small button group"
                            }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-outline-info",
                                class: { active: _vm.isCardLayout },
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.getCardView()
                                  }
                                }
                              },
                              [_vm._m(0)]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-outline-info",
                                class: { active: !_vm.isCardLayout },
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.getListView()
                                  }
                                }
                              },
                              [_vm._m(1)]
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "m-portlet__body project-list" },
                  [
                    !_vm.isCardLayout
                      ? _c(
                          "project-list-table",
                          {
                            attrs: {
                              loader: _vm.loadingList,
                              projectListData: _vm.projectListData,
                              selectedPM: _vm.selectedPM,
                              currentUser: _vm.currentUser
                            }
                          },
                          [
                            _c("metronic-pagination", {
                              attrs: { paginationConfig: _vm.paginationConfig }
                            })
                          ],
                          1
                        )
                      : _c("project-list-widget", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.projectSummaryDataLoading,
                              expression: "projectSummaryDataLoading"
                            }
                          ],
                          attrs: {
                            projectSummaryData: _vm.projectSummaryData,
                            projectSummaryChartSeries:
                              _vm.projectSummaryChartSeries,
                            projectSummaryChartData: _vm.projectSummaryChartData
                          }
                        })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "m-portlet m-portlet--head-sm" }, [
                    _c("div", { staticClass: "m-portlet__head" }, [
                      _c("div", { staticClass: "m-portlet__head-caption" }, [
                        _c("div", { staticClass: "m-portlet__head-title" }, [
                          _c("h3", { staticClass: "m-portlet__head-text" }, [
                            _vm._v(
                              _vm._s(_vm.selectedYear.getFullYear()) +
                                " Revenue vs Invoice"
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "m-portlet__head-tools" }, [
                        _c("ul", { staticClass: "m-portlet__nav" }, [
                          _c("li", { staticClass: "m-portlet__nav-item" }, [
                            _c(
                              "div",
                              { staticClass: "year-picker-selector" },
                              [
                                _c("el-year-picker", {
                                  attrs: {
                                    yearFormat: "yyyy",
                                    selectedYear: _vm.selectedYear
                                  },
                                  on: {
                                    "update:selectedYear": function($event) {
                                      _vm.selectedYear = $event
                                    },
                                    "update:selected-year": function($event) {
                                      _vm.selectedYear = $event
                                    },
                                    updatedYear: _vm.loadYearlyRevenueData
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "m-portlet__nav-item" },
                            [
                              _c("el-select-wrapper", {
                                attrs: {
                                  options: _vm.projectTypeFilterOptions,
                                  selected: _vm.selectedProjectType,
                                  placeholder: "Select Project Type"
                                },
                                on: {
                                  "update:selected": function($event) {
                                    _vm.selectedProjectType = $event
                                  },
                                  update: _vm.loadYearlyRevenueData
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "m-portlet__body" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.yearlyRevenueDataloading,
                              expression: "yearlyRevenueDataloading"
                            }
                          ],
                          staticClass: "row"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col-md-6",
                              class: {
                                "no-data": !_vm.yearlyRevenueChartData.length
                              }
                            },
                            [
                              _c("layered-column-chart", {
                                attrs: {
                                  series: _vm.yearlyRevenueChartSeries,
                                  data: _vm.yearlyRevenueChartData,
                                  xAxisLabel: "Months",
                                  yAxisLabel: "Amount($)",
                                  unit: "$",
                                  clustered: false,
                                  gridThickness: 1,
                                  columnWidth: "unEqual",
                                  categoryField: "month",
                                  valueAxisPosition: "left",
                                  rotate: false,
                                  showLegend: true,
                                  columnSpacing: true,
                                  categoryAxisPosition: "",
                                  chart_style: "height: 400px;"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-6 mt-20" },
                            [
                              _c("element-ui-table", {
                                attrs: {
                                  tableRows: _vm.yearlyRevenueChartData,
                                  stripe: true,
                                  border: true,
                                  headers: _vm.yearlyRevenueTableHeaders,
                                  height: "350"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.showSidebar
              ? _c("sidebar", [
                  _vm.showSidebar
                    ? _c(
                        "li",
                        {
                          staticClass: "m-nav-sticky__item",
                          attrs: {
                            "data-toggle": "m-tooltip",
                            title: "Snapshot Week Closure",
                            "data-placement": "left",
                            "data-original-title": "Snapshot Week Closure"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  _vm.openSnapshotWeekClosure = true
                                }
                              }
                            },
                            [_c("i", { staticClass: "flaticon-calendar" })]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showSidebar
                    ? _c(
                        "li",
                        {
                          staticClass: "m-nav-sticky__item",
                          attrs: {
                            "data-toggle": "m-tooltip",
                            title: "Snapshot Month Closure",
                            "data-placement": "left",
                            "data-original-title": "Snapshot Month Closure"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  _vm.openSnapshotMonthClosure = true
                                }
                              }
                            },
                            [_c("i", { staticClass: "flaticon-calendar-1" })]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showSidebar
                    ? _c(
                        "li",
                        {
                          staticClass: "m-nav-sticky__item",
                          attrs: {
                            "data-toggle": "m-tooltip",
                            title: "Sync Project Accrual",
                            "data-placement": "left",
                            "data-original-title": "Sync Project Plan"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  _vm.openSyncAccrualSheet = true
                                }
                              }
                            },
                            [_c("i", { staticClass: "flaticon-refresh" })]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("snapshot-week-closure", {
        attrs: { open: _vm.openSnapshotWeekClosure },
        on: {
          "update:open": function($event) {
            _vm.openSnapshotWeekClosure = $event
          }
        }
      }),
      _vm._v(" "),
      _c("snapshot-month-closure", {
        attrs: { open: _vm.openSnapshotMonthClosure },
        on: {
          "update:open": function($event) {
            _vm.openSnapshotMonthClosure = $event
          }
        }
      }),
      _vm._v(" "),
      _c("sync-accrual-sheet", {
        attrs: { open: _vm.openSyncAccrualSheet },
        on: {
          "update:open": function($event) {
            _vm.openSyncAccrualSheet = $event
          }
        }
      }),
      _vm._v(" "),
      _c("project-accrual-create", {
        attrs: {
          open: _vm.openProjectAccrualCreate,
          accrualType: "add",
          currentUserId: _vm.currentUser.id
        },
        on: {
          "update:open": function($event) {
            _vm.openProjectAccrualCreate = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "flaticon-squares-4" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "flaticon-list-2" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }