import { render, staticRenderFns } from "./project-summary.vue?vue&type=template&id=59a9fd62&"
import script from "./project-summary.js?vue&type=script&lang=js&"
export * from "./project-summary.js?vue&type=script&lang=js&"
import style0 from "./project-summary.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/web/bitool.softwaysolutions.com/htdocs/releases/20201012055253/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59a9fd62')) {
      api.createRecord('59a9fd62', component.options)
    } else {
      api.reload('59a9fd62', component.options)
    }
    module.hot.accept("./project-summary.vue?vue&type=template&id=59a9fd62&", function () {
      api.rerender('59a9fd62', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend/project-plan/project-summary/project-summary.vue"
export default component.exports