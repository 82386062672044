var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {}, [
      _c("div", { staticClass: "row parent" }, [
        _c(
          "div",
          {
            staticClass: "col-md-12",
            class: {
              "no-data": !_vm.dataAvailable,
              "min-height": !_vm.projectSummaryData.length
            }
          },
          [
            _c(
              "swiper",
              { ref: "mySwiper", attrs: { options: _vm.swiperOption } },
              [
                _vm._l(_vm.projectSummaryData, function(list, index) {
                  return _c(
                    "swiper-slide",
                    { key: index, staticClass: "col-xl-4 child" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "m-portlet m-portlet--full-height m-portlet--fit "
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "m-portlet__head m-bg-fix" },
                            [
                              _c(
                                "div",
                                { staticClass: "m-portlet__head-caption" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "m-portlet__head-title" },
                                    [
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "m-portlet__head-text m-heading-fix project-name-truncate",
                                          attrs: {
                                            "data-clipboard": "true",
                                            "data-toggle": "m-tooltip",
                                            "data-placement": "top",
                                            title: "",
                                            "data-original-title": list.name
                                          }
                                        },
                                        [_vm._v(_vm._s(list.name))]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "m-portlet__head-tools" },
                                [
                                  _c("ul", { staticClass: "m-portlet__nav" }, [
                                    _c(
                                      "li",
                                      { staticClass: "m-portlet__nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "m-portlet__nav-link",
                                            attrs: {
                                              to: {
                                                name: "project-accrual-report",
                                                params: { id: list.id }
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "btn m-btn--pill btn-outline-info btn-sm view-link"
                                              },
                                              [_vm._v("View")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "m-portlet__body summary-card-data"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "m-widget4 m-widget4--chart-bottom",
                                  staticStyle: {
                                    "min-height": "200px !important"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "m-widget4__item overwrite-styles"
                                    },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "m-widget4__img m-widget4__img--logo"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "m-widget4__info" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "m-widget4__title" },
                                            [_vm._v("Type")]
                                          ),
                                          _vm._v(" "),
                                          _c("br")
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "m-widget4__ext" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "m-widget4__number m-text-fix override"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  list.contract_type || "NA"
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "m-widget4__item overwrite-styles"
                                    },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "m-widget4__img m-widget4__img--logo"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "m-widget4__info" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "m-widget4__title" },
                                            [_vm._v("Jira Code")]
                                          ),
                                          _vm._v(" "),
                                          _c("br")
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "m-widget4__ext" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "m-widget4__number m-text-fix override"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(list.jira_code || "NA")
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "m-widget4__item overwrite-styles"
                                    },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "m-widget4__img m-widget4__img--logo"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "m-widget4__info" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "m-widget4__title" },
                                            [_vm._v("Revenue")]
                                          ),
                                          _vm._v(" "),
                                          _c("br")
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "m-widget4__ext" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "m-widget4__number m-text-fix"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm._f("number")(
                                                      list.total_revenue
                                                    )
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "m-widget4__item overwrite-styles"
                                    },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "m-widget4__img m-widget4__img--logo"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "m-widget4__info" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "m-widget4__title" },
                                            [_vm._v("Invoice")]
                                          ),
                                          _vm._v(" "),
                                          _c("br")
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "m-widget4__ext" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "m-widget4__number m-text-fix"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm._f("number")(
                                                      list.total_invoice
                                                    )
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("layered-column-chart", {
                                    attrs: {
                                      series: _vm.projectSummaryChartSeries,
                                      data: list.project_summary,
                                      xAxisLabel: "",
                                      yAxisLabel: "",
                                      unit: "$",
                                      clustered: true,
                                      gridThickness: 0,
                                      columnSpacing: true,
                                      columnWidth: "equal",
                                      categoryField: "month",
                                      valueAxisPosition: "bottom",
                                      rotate: false,
                                      categoryAxisPosition: "left",
                                      showLegend: false,
                                      chart_style: "height: 250px;"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "swiper-pagination",
                  attrs: { slot: "pagination" },
                  slot: "pagination"
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "swiper-pagination",
                  attrs: { slot: "pagination" },
                  slot: "pagination"
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "swiper-button-prev",
                  attrs: { slot: "button-prev" },
                  on: {
                    click: function($event) {
                      return _vm.onPrev()
                    }
                  },
                  slot: "button-prev"
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "swiper-button-next",
                  attrs: { slot: "button-next" },
                  on: {
                    click: function($event) {
                      return _vm.onNext()
                    }
                  },
                  slot: "button-next"
                })
              ],
              2
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }