var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "attachments-modal",
          attrs: { title: "Attachments", visible: _vm.open_ },
          on: {
            "update:visible": function($event) {
              _vm.open_ = $event
            },
            close: _vm.emitClose
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loader,
                  expression: "loader"
                }
              ],
              staticClass: "row"
            },
            [
              _c(
                "div",
                { staticClass: "col-lg-12" },
                [
                  _c(
                    "el-upload",
                    {
                      key: _vm.uploadKey,
                      staticClass: "upload-attachments",
                      attrs: {
                        drag: "",
                        action: _vm.attachmentsUploadUrl,
                        "file-list": _vm.attachments_,
                        "on-remove": _vm.handleFileRemove,
                        "http-request": _vm.HandleHttpRequest,
                        "on-preview": _vm.handlePreview
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v("Drop your file here or "),
                        _c("em", [_vm._v("click to upload")])
                      ])
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary cursor-pointer",
                  attrs: { type: "button", "close-on-click-modal": true },
                  on: { click: _vm.attachmentsUpdateCanceled }
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary cursor-pointer",
                  attrs: { type: "button" },
                  on: { click: _vm.attachmentsUpdated }
                },
                [_vm._v("\n        Save changes\n      ")]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }