var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-content" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c(
          "div",
          {
            staticClass: "m-portlet m-portlet--head-sm",
            attrs: { "m-portlet": "true" }
          },
          [
            _c("div", { staticClass: "m-portlet__head" }, [
              _c("div", { staticClass: "m-portlet__head-caption" }, [
                _c("div", { staticClass: "m-portlet__head-title" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("h3", { staticClass: "m-portlet__head-text" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.getSelectedProjectName) +
                        " - Burn Report\n              "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "m-portlet__head-tools" }, [
                _c("ul", { staticClass: "m-portlet__nav" }, [
                  _c(
                    "li",
                    { staticClass: "m-portlet__nav-item" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "ProjectBurnSummary" } } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-back",
                                size: "mini"
                              }
                            },
                            [_vm._v("Back")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "m-portlet__body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Project Name")
                      ]),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "Pick a Project"
                          },
                          on: {
                            input: function($event) {
                              return _vm.loadProjectBurnData()
                            }
                          },
                          model: {
                            value: _vm.selected_project,
                            callback: function($$v) {
                              _vm.selected_project = $$v
                            },
                            expression: "selected_project"
                          }
                        },
                        _vm._l(_vm.projects, function(project) {
                          return _c("el-option", {
                            key: project.id,
                            attrs: { label: project.name, value: project.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Date range")
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "fa fa-info-circle font-blue push-2-l",
                        attrs: {
                          "data-toggle": "m-tooltip",
                          "data-placement": "left",
                          title:
                            "Selected dates are auto adjusted to the beginning & end of the week/month."
                        }
                      }),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "To",
                          "start-placeholder": "Start date",
                          "end-placeholder": "End date",
                          format: "MM-dd-yyyy",
                          clearable: false,
                          "picker-options": _vm.pickerOptions,
                          disabled: _vm.show_lifetime
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadProjectBurnData()
                          }
                        },
                        model: {
                          value: _vm.selected_date_range,
                          callback: function($$v) {
                            _vm.selected_date_range = $$v
                          },
                          expression: "selected_date_range"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Report Period")
                      ]),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c("el-switch", {
                        attrs: {
                          "active-text": "Lifetime",
                          "inactive-text": "Range",
                          name: "Lifetime",
                          id: "period_toggler"
                        },
                        on: { change: _vm.toggleLifetime },
                        model: {
                          value: _vm.show_lifetime,
                          callback: function($$v) {
                            _vm.show_lifetime = $$v
                          },
                          expression: "show_lifetime"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Report Type")
                      ]),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c("el-switch", {
                        attrs: {
                          "active-text": "Dollars",
                          "inactive-text": "Hours",
                          name: "Type",
                          id: "dollar_toggler",
                          disabled: _vm.loading
                        },
                        on: { change: _vm.toggleDollarValue },
                        model: {
                          value: _vm.show_dollar_value,
                          callback: function($$v) {
                            _vm.show_dollar_value = $$v
                          },
                          expression: "show_dollar_value"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: { "tab-position": _vm.tabPosition },
                          on: { "tab-click": _vm.handleTabClick },
                          model: {
                            value: _vm.activeTabName,
                            callback: function($$v) {
                              _vm.activeTabName = $$v
                            },
                            expression: "activeTabName"
                          }
                        },
                        [
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "Daily Burn Chart",
                                name: "daily_burn_chart"
                              }
                            },
                            [
                              _c("daily-burn-chart", {
                                attrs: {
                                  lifetime_planned_vs_logged:
                                    _vm.lifetime_planned_vs_logged,
                                  show_dollar_value: _vm.show_dollar_value,
                                  selected_date_range: _vm.selected_date_range,
                                  show_lifetime: _vm.show_lifetime,
                                  chart_export: true,
                                  activeTabName: _vm.activeTabName,
                                  project_name: _vm.getSelectedProjectName
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "Burnup Chart",
                                name: "burn_up_chart"
                              }
                            },
                            [
                              _c("burn-up-chart", {
                                attrs: {
                                  lifetime_planned_vs_logged:
                                    _vm.lifetime_planned_vs_logged,
                                  show_dollar_value: _vm.show_dollar_value,
                                  selected_date_range: _vm.selected_date_range,
                                  show_lifetime: _vm.show_lifetime,
                                  chart_export: true,
                                  activeTabName: _vm.activeTabName,
                                  project_name: _vm.getSelectedProjectName
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      ref: "projectOverview",
                      attrs: {
                        placement: "right-start",
                        width: "400",
                        offset: "0",
                        trigger: "click",
                        "popper-class": "burn_report_pop_over"
                      }
                    },
                    [
                      _c("div", { staticClass: "m-widget24" }, [
                        _vm.show_dollar_value == true
                          ? _c("div", { staticClass: "m-widget24__item" }, [
                              _c("h4", { staticClass: "m-widget24__title" }, [
                                _vm._v(
                                  "\n                    Budget\n                  "
                                )
                              ]),
                              _c("br"),
                              _vm._v(" "),
                              _c("span", { staticClass: "m-widget24__desc" }, [
                                _vm._v("Project budget")
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "m-widget24__stats m--font-brand"
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.formatCurrency(
                                          _vm.project_summary.budget
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "m-widget24__item" }, [
                          _c("h4", { staticClass: "m-widget24__title" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  this.show_dollar_value == false
                                    ? "Total Planned"
                                    : "Total Estimated"
                                ) +
                                "\n                  "
                            )
                          ]),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "m-widget24__desc" }, [
                            _vm._v("Estimated value")
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "m-widget24__stats m--font-success"
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      _vm.project_summary.planned
                                    )
                                  ) +
                                  "\n                    "
                              ),
                              _c("small", { staticClass: "font-green-sharp" }, [
                                _vm._v(
                                  _vm._s(
                                    this.show_dollar_value == false ? "Hrs" : ""
                                  )
                                )
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "m-widget24__item" }, [
                          _c("h4", { staticClass: "m-widget24__title" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  this.show_dollar_value == false
                                    ? "Total Logged"
                                    : "Total Burned"
                                ) +
                                "\n                  "
                            )
                          ]),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "m-widget24__desc" }, [
                            _vm._v("Actual work value")
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "m-widget24__stats m--font-danger" },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      _vm.project_summary.logged
                                    )
                                  ) +
                                  "\n                    "
                              ),
                              _c("small", { staticClass: "font-green-sharp" }, [
                                _vm._v(
                                  _vm._s(
                                    this.show_dollar_value == false ? "Hrs" : ""
                                  )
                                )
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "m-widget24__item" }, [
                          _c("h4", { staticClass: "m-widget24__title" }, [
                            _vm._v(
                              "\n                    Running Difference\n                  "
                            )
                          ]),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "m-widget24__desc" }, [
                            _vm._v("Deviation from plan")
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "m-widget24__stats m--font-warning"
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.formatCurrency(_vm.runningDifference)
                                  ) +
                                  "\n                    "
                              ),
                              _c("small", { staticClass: "font-green-sharp" }, [
                                _vm._v(
                                  _vm._s(
                                    this.show_dollar_value == false ? "Hrs" : ""
                                  )
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "m--space-10" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "progress m-progress--sm" },
                            [
                              _c("div", {
                                staticClass: "progress-bar m--bg-brand",
                                style: {
                                  width: _vm.plannedProgress.toFixed(2) + "%"
                                },
                                attrs: {
                                  role: "progressbar",
                                  "aria-valuenow": "50",
                                  "aria-valuemin": "0",
                                  "aria-valuemax": "100"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "m-widget24__change" }, [
                            _vm._v("\n                    Planned Progress "),
                            _c("span", {
                              staticClass:
                                "fa fa-info-circle font-blue push-2-l",
                              attrs: {
                                "data-toggle": "m-tooltip",
                                "data-placement": "left",
                                title:
                                  "Percentage of project hours that's supposed to be completed as of today vs. the total planned hours."
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "m-widget24__number" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.plannedProgress.toFixed(2) + "%") +
                                "\n                  "
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-button", {
                    directives: [
                      {
                        name: "popover",
                        rawName: "v-popover:projectOverview",
                        arg: "projectOverview"
                      }
                    ],
                    ref: "overviewTrigger",
                    staticClass: "el-floating-button",
                    attrs: {
                      icon: "el-icon-more",
                      circle: "",
                      "data-toggle": "m-tooltip",
                      "data-placement": "left",
                      title: "Summary"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "m-portlet m-portlet--unair",
                      attrs: { "m-portlet": "true" }
                    },
                    [
                      _c("div", { staticClass: "m-portlet__head" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("div", { staticClass: "m-portlet__head-tools" }, [
                          _c("ul", { staticClass: "m-portlet__nav" }, [
                            _c("li", { staticClass: "m-portlet__nav-item" }, [
                              _c(
                                "a",
                                { attrs: { href: _vm.export_to_doc_url() } },
                                [
                                  _c("el-button", {
                                    staticClass: "pull-right",
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-download",
                                      "data-toggle": "m-tooltip",
                                      "data-placement": "left",
                                      title:
                                        "Download Project Status Report for selected dates.",
                                      circle: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "m-portlet__body" }, [
                        _c(
                          "div",
                          {
                            staticClass: "m-scrollable",
                            attrs: {
                              "data-scrollbar-shown": "true",
                              "data-scrollable": "true"
                            }
                          },
                          [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  border: "",
                                  data: _vm.inaccuracyPerTalent,
                                  "show-summary": "",
                                  "default-sort": {
                                    prop: "role",
                                    order: "descending"
                                  },
                                  "cell-class-name": _vm.tableCellClassName,
                                  "header-row-class-name":
                                    _vm.talentTableHeaderRowClass,
                                  "summary-method": _vm.getSummaries
                                }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "talent",
                                    label: "Talent",
                                    "min-width": "200",
                                    sortable: "",
                                    "show-overflow-tooltip": true,
                                    "sort-method": _vm.sortTalentColumn
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "role",
                                    label: "Role",
                                    "min-width": "180",
                                    sortable: "",
                                    "show-overflow-tooltip": true
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.valueBasedFontClass(
                                                scope.row.role
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.role == ""
                                                    ? "Not Available"
                                                    : scope.row.role
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _vm._v(" "),
                                _vm.show_dollar_value == true
                                  ? _c("el-table-column", {
                                      attrs: {
                                        prop: "hourly_rate",
                                        label: "Hourly Rate",
                                        "min-width": "140",
                                        sortable: "",
                                        "class-name": "text-right"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.hourly_rate.toLocaleString(
                                                        "en-US",
                                                        {
                                                          style: "currency",
                                                          currency: "USD"
                                                        }
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1290874036
                                      )
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "planned",
                                    label:
                                      _vm.show_dollar_value == false
                                        ? "Planned"
                                        : "Estimated",
                                    sortable: "",
                                    "class-name": "text-right"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.valueBasedFontClass(
                                                scope.row.planned
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    scope.row.planned
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "logged",
                                    label:
                                      _vm.show_dollar_value == false
                                        ? "Logged"
                                        : "Burned",
                                    sortable: "",
                                    "class-name": "text-right"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.valueBasedFontClass(
                                                scope.row.logged
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    scope.row.logged
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "difference",
                                    label: "Difference",
                                    sortable: "",
                                    "class-name": "text-right"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.valueBasedFontClass(
                                                scope.row.difference,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    scope.row.difference
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "inaccuracy",
                                    label: "Inaccuracy",
                                    sortable: "",
                                    "class-name": "text-right font-italic",
                                    "sort-method": _vm.sortInaccuracyColumn
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.valueBasedFontClass(
                                                scope.row.inaccuracy
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.conditionalFormatNumber(
                                                    scope.row.inaccuracy
                                                  ) +
                                                    (scope.row.inaccuracy ==
                                                    "N/A"
                                                      ? ""
                                                      : "%")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(2)
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "m-portlet__head-icon" }, [
      _c("i", { staticClass: "flaticon-stopwatch" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-portlet__head-caption" }, [
      _c("div", { staticClass: "m-portlet__head-title" }, [
        _c("h3", { staticClass: "m-portlet__head-text" }, [
          _vm._v(
            "\n                        Breakdown Per Talent\n                      "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", {
          staticStyle: { width: "100%", height: "400px" },
          attrs: { id: "talent_burndown_chart" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }