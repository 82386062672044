import LineChart from '../../../components/line-chart/line-chart.vue';
import elementTableWide from '../../../components/element-table-wide/element-table-wide.vue';
import talentForecastService from '../talent-forecast-service';
import { concatMap } from "rxjs/operators";
import QueryString from 'query-string';
import { copyToClipboard, dummyObservable, formatDateObject} from "../../../utils/utils";

export default {
  name: "TalentForecastReport",
  components: {
    LineChart,
    elementTableWide
  },
  data() {
    return {
      fixedColumns: [0],
      talentForecastService: talentForecastService,
      reportTypeFilter: 'Talent',
      filterOptions: [],
      threshold: 100,
      summaryThresholdPercentage: null,
      selectedName: [],
      locationOptions: [],
      roleOptions: "",
      recurrence: 'weekly',
      selectFilterOptions: [],
      locations: [],
      roles: "",
      totalForecastedHours: [],
      totalForecastedCollection: [],
      detailedForecastedHours: [],
      detailedForecastedCollection: [],
      selectedDateRange: [],
      // estimateStatus: 'forecast',
      estimateStatus: ['wip', 'win_pending'],
      tableTabs: '',
      tableTabsObj: {},
      tableForecastData: [],
      availableForecastData: [],
      holidayForecastData: [],
      selectedOption: 'planned',
      projectRoleData: [],
      tableForecastHeaders: [],
      elementTableWideKey: 0,
      viewType: 'Project',
      filterLimit: 2,
      forcastDataloading: false,
      detailedForecastDataloading: false,
      cellThreshold: null,
      datePickerOptions: {},
      detailedCollectionIds: [],
      viewTypeOptions: [{
          label: 'Talent View',
          value: 'Talent'
        },
        {
          label: 'Project View',
          value: 'Project'
        }
      ],
      // projectType: ["project_forecast"],
      projectType: "project_forecast",
      projectTypeOptions: [{
          value: "project_forecast",
          label: "Project forecast"
        }, {
          value: "overall_forecast",
          label: "Overall forecast"
      }],
      statusOptions: [{
          value: 'wip',
          label: 'WIP'
        }, {
          value: 'win_pending',
          label: 'Win Pending'
        }, {
          value: 'closed',
          label: 'Closed'
        }, {
          value: 'proposed',
          label: 'Proposed'
        }, {
          value: 'develop',
          label: 'Develop'
        }],
      estimateStatusKey: 0,
      thresholdMapping: {
        "daily": 8,
        "weekly": 40,
        "monthly": 160
      },
    }
  },

  computed: {
    detailedDataResponse() {
      
      return this.selectedOption === 'available' && this.reportTypeFilter !== 'Talent'? this.formatTableData(this.availableForecastData) : this.formatTableData(this.tableForecastData);
    }, 
    chartDetailedForecastedHours(){
      return this.detailedForecastedHours;
      // return this.detailedForecastedHours.length > 25 ? [] : this.detailedForecastedHours;
    },
    chartDetailedForecastedCollection(){
      return this.detailedForecastedCollection;
      // return this.detailedForecastedCollection.length > 25 ? [] : this.detailedForecastedCollection;
    },
    detailedChartThreshold() {
      if (['Talent', 'Project'].includes(this.reportTypeFilter)) {
        return null;
      } else if (this.viewType === 'Talent') {
        return this.thresholdMapping[this.recurrence];
      }

    },
    summaryChartThreshold() {
      if (['Talent'].includes(this.reportTypeFilter)) {
        return this.summaryThresholdPercentage;
      }
      return null;

    }
  },

  created() {
    this.setDefaultDateRange();
    this.setDatePickerOptions();
    this.attachLoaderHandlers();
    this.queryStringHandler();

  },

  mounted() {
    if (this.queryStringPresent) {
      this.queryStringInit()
    } else {
      this.defaultInit();
    }
  },

  watch: {
    reportTypeFilter(newVal, oldVal) {
      if (['Group', 'Vertical', 'Location'].includes(newVal)) {
        this.roleOptions = null; // Reset the selected role
      }
    },
  },

  methods: {

    selectedOption(newVal){
      if(newVal === 'available'){
        return this.formatTableData(this.availableForecastData)
      }else{
        return this.formatTableData(this.tableForecastData)
      }
    },
    holidayDataResponse(name, date) {
      const formattedData = this.formatTableData(this.holidayForecastData);

      for (const item of formattedData) {
        if (item.name === name) {
          return item[date];
        }
      }
     
    },
    projectRoleDataResponse(name) {
      const formattedData = this.projectRoleData[name]     
      if (Array.isArray(formattedData) && formattedData.length > 0) {
        const fullString = formattedData.join(", ");
        return fullString;
    } else {
        return formattedData; 
    }
     
    },
    refresh() {
      this.$nextTick(() => {
        let elTable = this.$children.find(a => a.$vnode.tag.indexOf('ElTable') >= 0);
          if (elTable) {
            let bodyAndHeaders = elTable.$children.filter(a => a.$vnode.tag.indexOf('ElTableBody') >= 0 || a.$vnode.tag.indexOf('ElTableHeader') >= 0 || a.$vnode.tag.indexOf('ElTableFooter') >= 0)
            bodyAndHeaders.map(bodyOrHeaderOrFooter => {
              Object.keys(bodyOrHeaderOrFooter._computedWatchers).forEach(k => bodyOrHeaderOrFooter._computedWatchers[k].run())
              bodyOrHeaderOrFooter.$forceUpdate();
            })
          }
      })
    },
    defaultInit() {
      console.log("filter tyoe" ,this.reportTypeFilter )
      this.init$ = this.talentForecastService
        .getFilterOptions(this.reportTypeFilter)
        .pipe(
          concatMap(filterOptions => {
            this.selectFilterOptions = filterOptions;
            return this.talentForecastService.getLocations();
          }),
          concatMap(locationFilterOptions => {
            console.log("data one ", locationFilterOptions)
            this.locations = locationFilterOptions;
            return this.talentForecastService.getRoles();
          }),
          concatMap(rolesFilterOptions => {
            console.log("data one ", rolesFilterOptions)
            this.roles = rolesFilterOptions;
            return this.talentForecastService.getCurrentUser();
          }),
          concatMap(currentUser => {
            this.selectedName = [currentUser];
            return this.getForecastSummary();
          }),
          concatMap(forecastData => {
            this.setForecastSummary(forecastData);
            return this.getForecastDetailed();
          })
        )
        .subscribe(
          this.setForecastDetailed.bind(this),
          error => {
            this.totalForecastedHours = [],
            this.totalForecastedCollection = [],
            this.detailedForecastedHours = [],
            this.detailedForecastedCollection = []
            bootstrapNotify('Error in loading the report.\n Please retry', 'error');
          }
        );
    },

    queryStringInit() {
      let nameFiltersExists = true;
      this.init$ = this.talentForecastService
        .getFilterOptions(this.reportTypeFilter)
        .pipe(
          concatMap(filterOptions => {
            this.selectFilterOptions = filterOptions;
            return this.talentForecastService.getLocations();
          }),
          concatMap(locationFilterOptions => {
            console.log("data 2 ", locationFilterOptions)

            this.locations = locationFilterOptions;
            nameFiltersExists = this.nameFiltersExists();
            if (nameFiltersExists) {
              return this.getForecastSummary();
            } else {
              this.selectedName = [];
              bootstrapNotify(this.reportTypeFilter + ' requested in the URL is currently inactive.', 'info');
              return dummyObservable(null);
            }
          }),
          concatMap(forecastData => {
            if (!nameFiltersExists) {
              return dummyObservable(null);
            }
            this.setForecastSummary(forecastData);
            return this.getForecastDetailed();
          })
        )
        .subscribe((detailedForecasted) => {
          if (!nameFiltersExists) {
            return;
          }
          this.setForecastDetailed(detailedForecasted);
        },
          error => {
            this.totalForecastedHours = [],
            this.totalForecastedCollection = []
            bootstrapNotify('Error in loading the report.\n Please retry', 'error');
          }
        );
    },

    setDefaultDateRange() {
      const end = new Date();
      const start = new Date();
      // start.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
      end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
      this.selectedDateRange = [start, end];
    },

    setDatePickerOptions() {
      this.datePickerOptions = {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    },

    attachLoaderHandlers() {
      this.talentForecastService.forcastDataloading
        .subscribe(
          response => this.forcastDataloading = response
        );
      this.talentForecastService.detailedForecastDataloading
        .subscribe(
          response => this.detailedForecastDataloading = response
        );
    },

    getForecastSummary() {
      let selectedNameArray = this.selectedName.slice();
      this.tableTabs = (this.selectedName.length > 0) ? this.selectedName[this.selectedName.length - 1].name : '';
      this.tableTabsObj = (this.selectedName.length > 0) ? this.selectedName[this.selectedName.length - 1] : {};
      return this.talentForecastService.loadForecastData(
        this.reportTypeFilter,
        this.selectedDateRange[0],
        this.selectedDateRange[1],
        this.recurrence,
        this.reportTypeFilter === 'Project' ? ['wip', 'win_pending'] : this.estimateStatus,
        selectedNameArray.map(item => item.id),
        this.reportTypeFilter === 'Talent' ? this.threshold : '',
        this.locationOptions,
        this.roleOptions,
        this.projectType
      );
    },

    setForecastSummary(forecastData) {
      this.totalForecastedHours = forecastData.totalEstimateHours.hours_per_recurrence;
      this.totalForecastedCollection = forecastData.totalEstimateHours.collection;
      this.summaryThresholdPercentage = (forecastData.totalEstimateHours && forecastData.totalEstimateHours.threshold_hours.length) ? forecastData.totalEstimateHours.threshold_hours[0] : null;     
      this.refresh();   
    },

    getForecastDetailed() {
      return this.talentForecastService.loadDetailedForecastData(
        this.reportTypeFilter,
        this.selectedDateRange[0],
        this.selectedDateRange[1],
        this.recurrence,
        this.reportTypeFilter === 'Project' ? ['wip', 'win_pending'] : this.estimateStatus,
        [this.tableTabsObj.id],
        this.threshold,
        this.locationOptions,
        this.roleOptions,
        this.viewType,
        this.projectType
      )
    },

    setForecastDetailed(detailedData) {
      this.detailedForecastedHours = detailedData.hoursPerTalent ? detailedData.hoursPerTalent.hours_per_recurrence : [];
      this.detailedForecastedCollection = detailedData.hoursPerTalent ? detailedData.hoursPerTalent.collection : [];
      this.cellThreshold = (detailedData.hoursPerTalent && detailedData.hoursPerTalent.threshold_hours.length) ? detailedData.hoursPerTalent.threshold_hours[0] : null ;
      this.tableForecastHeaders = detailedData['header_row'];

      if (detailedData['hoursPerTalent']) {
        this.tableForecastData = detailedData['hoursPerTalent']['hours_per_recurrence'];
        this.availableForecastData = detailedData['hoursPerTalent']['available'];
        this.holidayForecastData = detailedData['hoursPerTalent']['hours_per_holidays'];
        this.projectRoleData =  detailedData['hoursPerTalent']['project_role'];

        this.detailedCollectionIds = this.viewType === 'Talent' ? detailedData['hoursPerTalent']['employee_ids'] : detailedData['hoursPerTalent']['ids'];
      }

      this.elementTableWideKey++;
      this.refresh();
    },

    changeViewType(viewType) {
      if (this.viewType === viewType) {
        return;
      }

      this.viewType = viewType;
      this.handleView$ ? this.handleView$.unsubscribe() : '';
      this.handleView$ = this.getForecastDetailed()
        .subscribe(
          this.setForecastDetailed.bind(this),
          error => {
            this.detailedForecastedHours = [];
            this.detailedForecastedCollection = [];
            bootstrapNotify('Error in loading the report.\n Please retry', 'error');
          }
        );
    },

    handleNameTabChange(tabValue) {
      this.tableTabsObj = tabValue;
      this.handleNameTabChange$ ? this.handleNameTabChange$.unsubscribe() : '';
      this.handleNameTabChange$ = this.getForecastDetailed()
      .subscribe(
        this.setForecastDetailed.bind(this),
        error => {
          this.detailedForecastedHours = [];
          this.detailedForecastedCollection = [];
          bootstrapNotify('Error in loading the report.\n Please retry', 'error');
        }
      );
    },

    // API response need to be formatted to fit the element table data format.
    // Since the same api is used in multiple place; formatting is implemented.
    formatTableData(response) {
      var formattedData = {};
      let vm = this;
      response.forEach((forecastPerDate) => {
        for (let [key, value] of Object.entries(forecastPerDate)) {
          if (key != 'date') {
            if (formattedData[key]) {
              formattedData[key][forecastPerDate['date']] = value;
            } else {
              formattedData[key] = {
                name: key,
                href: this.getForecastUrl(key)
              }
              formattedData[key][forecastPerDate['date']] = value;
            }
          }
        }
      });
      vm.refresh();
      return Object.values(formattedData);
    },

    onFilterTypeChange(type) {
      if (this.reportTypeFilter === type) {
        return;
      }

      this.estimateStatusKey++;
      switch (type) {
        case 'Talent':
          this.viewType = 'Project';
          break;
        case 'Group':
        case 'Vertical':
        case 'Location':
        case 'Project':
          this.viewType = 'Talent';
          break;

        default:
          this.viewType = 'Talent';
          break;
      }

      this.selectedName = [];
      this.reportTypeFilter = type;
      if (type === 'Project') {
        // this.estimateStatus = "forecast";
        this.estimateStatus = ["wip", "win_pending"];
      }
      this.getFilterOptions();
    },

    getFilterOptions() {
      this.getFilter$ ? this.getFilter$.unsubscribe() : '';
      this.getFilter$ = this.talentForecastService.getFilterOptions(this.reportTypeFilter)
        .subscribe(
          response => {
            this.selectFilterOptions = response;
            this.$refs.selectFilter.focus();
          },
          error => {
            bootstrapNotify('Error in loading the report.\n Please retry', 'error');
          }
        );
    },

    loadForecastData() {
      if (this.selectedName.length <= 0) {
        return;
      }
      this.$refs.selectFilter.blur();
      this.loadForecastData$ ? this.loadForecastData$.unsubscribe() : '';
      this.loadForecastData$ = this.getForecastSummary()
        .pipe(
          concatMap(
            response => {
              this.setForecastSummary(response);
              return this.getForecastDetailed();
            }
          )
        )
        .subscribe(
          this.setForecastDetailed.bind(this),
          error => {
            this.totalForecastedHours = [],
            this.totalForecastedCollection = [],
            this.detailedForecastedHours = [],
            this.detailedForecastedCollection = []
            bootstrapNotify('Error in loading the report.\n Please retry', 'error');
          }
        );
    },

    queryStringHandler() {
      const queryString = QueryString.parse(window.location.search);
      if (!Object.keys(queryString).length) {
        this.queryStringPresent = false;
        return;
      }

      this.queryStringPresent = true;
      this.reportTypeFilter = queryString.reportTypeFilter;
      this.viewType = queryString.reportTypeFilter === 'Project' ? 'Talent' : 'Project';
      // this.tempFilterName = JSON.parse(queryString.nameFilters);
        this.tempFilterName = (typeof queryString.nameFilters === 'string') ?
          [parseFloat(queryString.nameFilters)] : queryString.nameFilters.map(ele => parseFloat(ele));

      if (queryString.selectedDateRange) {
        this.selectedDateRange = [new Date(queryString.selectedDateRange[0]), new Date(queryString.selectedDateRange[1])];
      }

      if (queryString.threshold) {
        this.threshold = parseFloat(queryString.threshold);
      }

      if (queryString.locationSelected) {
        this.locationOptions = (typeof queryString.locationSelected === 'string') ?
        [parseFloat(queryString.locationSelected)] : queryString.locationSelected.map(ele => parseFloat(ele));
      }
    },

    nameFiltersExists() {
      let filterObj = [];
      if (this.tempFilterName.length && this.tempFilterName.length == 1) {
        filterObj = this.selectFilterOptions.filter(item => {
          return (item.id === this.tempFilterName[0]);
        })
      } else if (this.tempFilterName.length && this.tempFilterName.length == 2) {
        filterObj = this.selectFilterOptions.filter(item => {
          return (item.id === this.tempFilterName[0]) || (item.id === this.tempFilterName[1]);
        })
      }
      this.selectedName = filterObj;
      return filterObj.length ? true : false;
    },

    copyForecastUrl(event) {
      if (event) event.preventDefault();
      const selectedDateRangeClone = this.selectedDateRange.slice();
      const selectedNameClone = this.selectedName.slice();

      const query = {
        viewType: this.viewType,
        reportTypeFilter: this.reportTypeFilter,
        nameFilters: selectedNameClone.map(ele => ele.id),
        selectedDateRange: selectedDateRangeClone.map(date => formatDateObject(date, '/')),
        threshold: this.threshold,
        locationSelected: this.locationOptions,
        roleSelected: this.roleOptions
      }
      const stringified = QueryString.stringify(query);
      copyToClipboard(window.location.origin + window.location.pathname + '?' + stringified);
    },
    exportReports() {
      const date = moment().format('MM-DD-YYYY')
      const fileName = "TalentForecast"+ "_" + date
      this.$refs.forecastBreakdownTable.exportData(
        {
          type: 'xlsx',
          filename: fileName,
          sheetName: fileName,
          message: false,
          isHeader: true
        }
      )
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = 0;
        }
        sums[index] = sums[index].toFixed(1)
      });
      // this.refresh();
      return sums;
    },
    cellFontClass(value) {
      var classes = [];
      if (this.cellThreshold && typeof (value) == "number") {
        var parsedValue = parseFloat(value);
        switch (true) {
          case (parsedValue == 0 || isNaN(parsedValue)):
            classes.push("is-zero");
            break;
          case (parsedValue > this.cellThreshold):
            classes.push("is-overutilized");
            break;
          default:
            classes.push("");
            break;
        }
      }
      return classes;
    },
    getForecastUrl(name) {
      const index = this.detailedForecastedCollection.indexOf(name);
      if (!this.detailedCollectionIds[index]) {
        return null;
      }
      const nameFilters = [this.detailedCollectionIds[index]];
      const selectedDateRangeClone = this.selectedDateRange.slice();

      const query = {
        viewType: this.viewType,
        reportTypeFilter: this.viewType,
        nameFilters: nameFilters,
        selectedDateRange: selectedDateRangeClone.map(date => formatDateObject(date, '/')),
      }
      const stringified = QueryString.stringify(query);
      return window.location.origin + window.location.pathname + '?' + stringified;

    },
  },
  beforeDestroy() {
    this.init$ ? this.init$.unsubscribe() : '';
    this.getFilter$ ? this.getFilter$.unsubscribe() : '';
    this.handleView$ ? this.handleView$.unsubscribe() : '';
    this.loadForecastData$ ? this.loadForecastData$.unsubscribe() : '';
  }
}