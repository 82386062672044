var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-content" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c(
            "div",
            {
              staticClass: "m-portlet m-portlet--head-sm",
              attrs: { "m-portlet": "true" }
            },
            [
              _c("div", { staticClass: "m-portlet__head" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "m-portlet__head-tools" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "btn-group btn-group-sm push-10-r scope-search"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn m-btn btn-outline-info m-btn--air btn-sm",
                          class: _vm.statusToggleClass("all"),
                          on: {
                            click: function($event) {
                              return _vm.toggleProjectStatus("all")
                            }
                          }
                        },
                        [_vm._v("All Projects")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn m-btn btn-outline-info m-btn--air btn-sm",
                          class: _vm.statusToggleClass("wip"),
                          on: {
                            click: function($event) {
                              return _vm.toggleProjectStatus("wip")
                            }
                          }
                        },
                        [_vm._v("WIP")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn m-btn btn-outline-info m-btn--air btn-sm",
                          class: _vm.statusToggleClass("closed"),
                          on: {
                            click: function($event) {
                              return _vm.toggleProjectStatus("closed")
                            }
                          }
                        },
                        [_vm._v("Closed")]
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "m-portlet__body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Project Name")
                        ]),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c(
                          "el-input",
                          {
                            attrs: { placeholder: "Search" },
                            on: {
                              input: function($event) {
                                return _vm.resetCurrentPage()
                              }
                            },
                            model: {
                              value: _vm.customFilters[0].value,
                              callback: function($$v) {
                                _vm.$set(_vm.customFilters[0], "value", $$v)
                              },
                              expression: "customFilters[0].value"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-input__icon el-icon-search",
                              attrs: { slot: "prefix" },
                              slot: "prefix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Date range")
                        ]),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            "range-separator": "To",
                            "start-placeholder": "Start date",
                            "end-placeholder": "End date",
                            format: "MM-dd-yyyy",
                            "value-format": "MM-dd-yyyy",
                            clearable: false,
                            "picker-options": _vm.pickerOptions
                          },
                          on: { input: _vm.getBurnReportSummary },
                          model: {
                            value: _vm.selected_date_range,
                            callback: function($$v) {
                              _vm.selected_date_range = $$v
                            },
                            expression: "selected_date_range"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Contract Type")
                        ]),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            attrs: { multiple: "multiple" },
                            on: {
                              input: function($event) {
                                return _vm.resetCurrentPage()
                              }
                            },
                            model: {
                              value: _vm.customFilters[1].value,
                              callback: function($$v) {
                                _vm.$set(_vm.customFilters[1], "value", $$v)
                              },
                              expression: "customFilters[1].value"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                label: "Time and Material",
                                value: "Time and Material"
                              }
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "Fixed Bid", value: "Fixed Bid" }
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "Retainer", value: "Retainer" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Report Type")
                        ]),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-text": "Dollars",
                            "inactive-text": "Hours",
                            name: "Type",
                            id: "dollar_toggler",
                            disabled: _vm.loading
                          },
                          on: { change: _vm.toggleDollarValue },
                          model: {
                            value: _vm.show_dollar_value,
                            callback: function($$v) {
                              _vm.show_dollar_value = $$v
                            },
                            expression: "show_dollar_value"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      { staticClass: "table-container" },
                      [
                        _c(
                          "data-tables",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading"
                              }
                            ],
                            ref: "dataTable",
                            attrs: {
                              data: _vm.burnReportData,
                              "search-def": _vm.searchDef,
                              "table-props": _vm.tableProps,
                              filters: _vm.customFilters,
                              "pagination-def": _vm.paginationDef,
                              border: ""
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "project_name",
                                label: "Project (Jira Key)",
                                sortable: "",
                                "min-width": "180",
                                fixed: ""
                              }
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "contract_type",
                                label: "Contract Type",
                                sortable: "",
                                "min-width": "150",
                                fixed: ""
                              }
                            }),
                            _vm._v(" "),
                            this.show_dollar_value == true
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "budget",
                                    label: "Budget",
                                    sortable: "",
                                    "min-width": "120",
                                    "class-name": "text-right font-italic"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.budget.toLocaleString(
                                                    "en-US",
                                                    {
                                                      style: "currency",
                                                      currency: "USD"
                                                    }
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2073877529
                                  )
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-table-column",
                              { attrs: { label: "Lifetime" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "total_planned",
                                    label:
                                      _vm.show_dollar_value == false
                                        ? "Planned"
                                        : "Estimated",
                                    "class-name": "text-right font-italic",
                                    "min-width": "120",
                                    sortable: ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.valueBasedFontClass(
                                                scope.row.total_planned
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    scope.row.total_planned
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "total_logged",
                                    label:
                                      _vm.show_dollar_value == false
                                        ? "Logged"
                                        : "Burned",
                                    "class-name": "text-right font-italic",
                                    "min-width": "120",
                                    sortable: ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.valueBasedFontClass(
                                                scope.row.total_logged
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    scope.row.total_logged
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "total_difference",
                                    label: "Difference",
                                    "class-name": "text-right font-italic",
                                    "min-width": "120",
                                    sortable: ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.valueBasedFontClass(
                                                scope.row.total_difference,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    scope.row.total_difference
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-table-column",
                              { attrs: { label: "Current Period" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "planned",
                                    label:
                                      _vm.show_dollar_value == false
                                        ? "Planned"
                                        : "Estimated",
                                    "class-name": "text-right font-italic",
                                    "min-width": "120",
                                    sortable: ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.valueBasedFontClass(
                                                scope.row.planned
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    scope.row.planned
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "logged",
                                    label:
                                      _vm.show_dollar_value == false
                                        ? "Logged"
                                        : "Burned",
                                    "class-name": "text-right font-italic",
                                    "min-width": "120",
                                    sortable: ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.valueBasedFontClass(
                                                scope.row.logged
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    scope.row.logged
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "difference",
                                    label: "Difference",
                                    "class-name": "text-right font-italic",
                                    "min-width": "120",
                                    sortable: ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.valueBasedFontClass(
                                                scope.row.difference,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    scope.row.difference
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "inaccuracy",
                                    label: "Inaccuracy",
                                    "sort-method": _vm.sortInaccuracyColumn,
                                    "class-name": "text-right font-italic",
                                    "min-width": "120",
                                    sortable: ""
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.valueBasedFontClass(
                                                scope.row.inaccuracy
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.conditionalFormatNumber(
                                                    scope.row.inaccuracy
                                                  ) +
                                                    (scope.row.inaccuracy ==
                                                    "N/A"
                                                      ? ""
                                                      : "%")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "Actions",
                                width: "140",
                                fixed: "right"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(
                                      _vm.customButtonsForRow(scope.row),
                                      function(button) {
                                        return _c(
                                          "el-button",
                                          {
                                            key: button.name,
                                            attrs: {
                                              type: button.type,
                                              size: button.size,
                                              disabled: button.disabled
                                            },
                                            on: { click: button.handler }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(button.name) +
                                                "\n                      "
                                            )
                                          ]
                                        )
                                      }
                                    )
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "Link Jira Project", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.onCloseModal
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.selected_row, rules: _vm.rules }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Project", "label-width": "100px" } },
                [
                  _c("el-input", {
                    attrs: { "auto-complete": "off", disabled: true },
                    model: {
                      value: _vm.selected_row.project_name,
                      callback: function($$v) {
                        _vm.$set(_vm.selected_row, "project_name", $$v)
                      },
                      expression: "selected_row.project_name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Jira Code",
                    "label-width": "100px",
                    prop: "jira_key"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "Please select a Jira project code"
                      },
                      model: {
                        value: _vm.selected_row.jira_key,
                        callback: function($$v) {
                          _vm.$set(_vm.selected_row, "jira_key", $$v)
                        },
                        expression: "selected_row.jira_key"
                      }
                    },
                    _vm._l(_vm.projectKeyList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("If your project is missing, ")]),
                  _c("el-button", {
                    attrs: { type: "text" },
                    domProps: { innerHTML: _vm._s(_vm.syncButtonHtml) },
                    on: { click: _vm.syncJiraProjectCodes }
                  }),
                  _c("span", [_vm._v(" from Jira.")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitButtonLoading },
                  on: {
                    click: function($event) {
                      return _vm.linkJiraProjectKey()
                    }
                  }
                },
                [_vm._v("Submit")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-portlet__head-caption" }, [
      _c("div", { staticClass: "m-portlet__head-title" }, [
        _c("span", { staticClass: "m-portlet__head-icon" }, [
          _c("i", { staticClass: "flaticon-stopwatch" })
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "m-portlet__head-text" }, [
          _vm._v("\n                Project Burn Summary\n              ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }