var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-content" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c(
          "div",
          {
            staticClass: "m-portlet m-portlet--head-sm",
            attrs: { "m-portlet": "true" }
          },
          [
            _c("div", { staticClass: "m-portlet__head" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "m-portlet__head-tools" }, [
                _c("div", { staticClass: "btn-group btn-group-sm push-10-r" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-sm btn-outline-info m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill copy-btn",
                      attrs: {
                        "data-clipboard": "true",
                        "data-toggle": "m-tooltip",
                        "data-placement": "top",
                        title: "",
                        "data-original-title": "Copy sharable URL"
                      },
                      on: { click: _vm.copyForecastUrl }
                    },
                    [_c("i", { staticClass: "la la-copy" })]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "btn-group btn-group-sm push-10-r" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-sm btn-outline-info m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill copy-btn",
                      attrs: {
                        "data-clipboard": "true",
                        "data-toggle": "m-tooltip",
                        "data-placement": "top",
                        title: "",
                        "data-original-title": "Export"
                      },
                      on: { click: _vm.exportReports }
                    },
                    [_c("i", { staticClass: "fa fa-file-excel" })]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "btn-group btn-group-sm push-10-r scope-search"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn m-btn btn-outline-info btn-sm",
                        class: { active: _vm.reportTypeFilter === "Talent" },
                        on: {
                          click: function($event) {
                            return _vm.onFilterTypeChange("Talent")
                          }
                        }
                      },
                      [_vm._v("Talent")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn m-btn btn-outline-info btn-sm",
                        class: {
                          active: _vm.reportTypeFilter === "Capability"
                        },
                        on: {
                          click: function($event) {
                            return _vm.onFilterTypeChange("Capability")
                          }
                        }
                      },
                      [_vm._v("Roles")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn m-btn btn-outline-info btn-sm",
                        class: { active: _vm.reportTypeFilter === "Vertical" },
                        on: {
                          click: function($event) {
                            return _vm.onFilterTypeChange("Vertical")
                          }
                        }
                      },
                      [_vm._v("Function/Portfolio")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn m-btn btn-outline-info btn-sm",
                        class: { active: _vm.reportTypeFilter === "Project" },
                        on: {
                          click: function($event) {
                            return _vm.onFilterTypeChange("Project")
                          }
                        }
                      },
                      [_vm._v("Project")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn m-btn btn-outline-info btn-sm",
                        class: { active: _vm.reportTypeFilter === "Location" },
                        on: {
                          click: function($event) {
                            return _vm.onFilterTypeChange("Location")
                          }
                        }
                      },
                      [_vm._v("Location")]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "m-portlet__body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Filter By")
                      ]),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          ref: "selectFilter",
                          staticClass: "select-filter__wrap",
                          attrs: {
                            "value-key": "name",
                            multiple: "multiple",
                            "multiple-limit": _vm.filterLimit,
                            filterable: "",
                            clearable: "",
                            placeholder: "Select upto 2 names"
                          },
                          on: {
                            input: function($event) {
                              return _vm.loadForecastData()
                            }
                          },
                          model: {
                            value: _vm.selectedName,
                            callback: function($$v) {
                              _vm.selectedName = $$v
                            },
                            expression: "selectedName"
                          }
                        },
                        _vm._l(_vm.selectFilterOptions, function(project) {
                          return _c("el-option", {
                            key: project.id,
                            attrs: { label: project.name, value: project }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Date range")
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "fa fa-info-circle font-blue push-2-l",
                        attrs: {
                          "data-toggle": "m-tooltip",
                          "data-placement": "left",
                          title:
                            "Selected dates are auto adjusted to the beginning & end of the week/month."
                        }
                      }),
                      _vm._v(" "),
                      _c("div"),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "To",
                          "start-placeholder": "Start date",
                          "end-placeholder": "End date",
                          format: "MM-dd-yyyy",
                          clearable: false,
                          "picker-options": _vm.datePickerOptions
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadForecastData()
                          }
                        },
                        model: {
                          value: _vm.selectedDateRange,
                          callback: function($$v) {
                            _vm.selectedDateRange = $$v
                          },
                          expression: "selectedDateRange"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.reportTypeFilter === "Vertical" ||
                _vm.reportTypeFilter === "Group" ||
                _vm.reportTypeFilter === "Project" ||
                _vm.reportTypeFilter === "Capability"
                  ? _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Location")
                          ]),
                          _vm._v(" "),
                          _c("div"),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "multiple",
                                filterable: "",
                                clearable: "",
                                placeholder: "Choose a Location"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.loadForecastData()
                                }
                              },
                              model: {
                                value: _vm.locationOptions,
                                callback: function($$v) {
                                  _vm.locationOptions = $$v
                                },
                                expression: "locationOptions"
                              }
                            },
                            _vm._l(_vm.locations, function(location) {
                              return _c("el-option", {
                                key: location.id,
                                attrs: {
                                  label: location.name,
                                  value: location.id
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.reportTypeFilter === "Talent"
                  ? _c("div", { staticClass: "col-md-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Threshold in %")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "input-group" },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  controls: false,
                                  min: 1,
                                  max: 100,
                                  id: "threshold_value",
                                  type: "number",
                                  value: "100"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.loadForecastData()
                                  }
                                },
                                model: {
                                  value: _vm.threshold,
                                  callback: function($$v) {
                                    _vm.threshold = $$v
                                  },
                                  expression: "threshold"
                                }
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("%")
                                ])
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.reportTypeFilter !== "Project"
                  ? _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Project Status")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              key: _vm.estimateStatusKey,
                              attrs: {
                                multiple: "",
                                "collapse-tags": "",
                                placeholder: "Select project status"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.loadForecastData()
                                }
                              },
                              model: {
                                value: _vm.estimateStatus,
                                callback: function($$v) {
                                  _vm.estimateStatus = $$v
                                },
                                expression: "estimateStatus"
                              }
                            },
                            _vm._l(_vm.statusOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.reportTypeFilter === "Project"
                  ? _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Report type")
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              key: _vm.estimateStatusKey,
                              attrs: { placeholder: "Select report type" },
                              on: {
                                change: function($event) {
                                  return _vm.loadForecastData()
                                }
                              },
                              model: {
                                value: _vm.projectType,
                                callback: function($$v) {
                                  _vm.projectType = $$v
                                },
                                expression: "projectType"
                              }
                            },
                            _vm._l(_vm.projectTypeOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row forecast-chart-tabs" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: {
                          "tab-click": function($event) {
                            return _vm.loadForecastData()
                          }
                        },
                        model: {
                          value: _vm.recurrence,
                          callback: function($$v) {
                            _vm.recurrence = $$v
                          },
                          expression: "recurrence"
                        }
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: {
                            label: "Daily Report",
                            id: "daily",
                            name: "daily"
                          }
                        }),
                        _vm._v(" "),
                        _c("el-tab-pane", {
                          attrs: {
                            label: "Weekly Report",
                            id: "weekly",
                            name: "weekly"
                          }
                        }),
                        _vm._v(" "),
                        _c("el-tab-pane", {
                          attrs: {
                            label: "Monthly Report",
                            id: "monthly",
                            name: "monthly"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.forcastDataloading,
                        expression: "forcastDataloading"
                      }
                    ],
                    staticClass: "col-md-12",
                    class: {
                      "no-data":
                        !_vm.selectedName.length ||
                        !_vm.totalForecastedCollection.length
                    }
                  },
                  [
                    _c("LineChart", {
                      attrs: {
                        data: _vm.totalForecastedHours,
                        collection: _vm.totalForecastedCollection,
                        xAxisKey: "date",
                        yAxisLabel: "Total Forecasted Hours",
                        threshold: _vm.summaryChartThreshold,
                        balloonTextValuePostfix: "h",
                        scrollBar: true,
                        showBalloonText: true
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "m-portlet m-portlet--unair" }, [
                    _c("div", { staticClass: "m-portlet__head left-align" }, [
                      _c("div", { staticClass: "m-portlet__head-caption" }, [
                        _c("div", { staticClass: "m-portlet__head-title" }, [
                          _c("h3", { staticClass: "m-portlet__head-text" }, [
                            _vm._v("Hours Per " + _vm._s(_vm.viewType))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.selectedName.length > 0 &&
                      (_vm.reportTypeFilter === "Group" ||
                        _vm.reportTypeFilter === "Vertical")
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "m-portlet__head-tools pull-right push-10-t"
                            },
                            [
                              _c("ul", { staticClass: "m-portlet__nav" }, [
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "m-portlet__nav-item m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push",
                                    attrs: { "m-dropdown-toggle": "hover" }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "m-portlet__nav-link m-dropdown__toggle dropdown-toggle btn m-btn--sm m-btn--pill btn-secondary m-btn m-btn--label-brand m--font-brand"
                                      },
                                      [_vm._v(_vm._s(_vm.viewType) + " View")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "m-dropdown__wrapper" },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "m-dropdown__inner" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "m-dropdown__body"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "m-dropdown__content"
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      { staticClass: "m-nav" },
                                                      _vm._l(
                                                        _vm.viewTypeOptions,
                                                        function(option) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key: option.label,
                                                              staticClass:
                                                                "m-nav__item"
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "m-nav__link view-option-popup",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeViewType(
                                                                        option.value
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "m-nav__link-text"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          option.label
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-portlet__body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 custom-gap-all" },
                        [
                          _c(
                            "el-tabs",
                            {
                              attrs: { "value-key": "id" },
                              on: {
                                "tab-click": function($event) {
                                  return _vm.handleNameTabChange(
                                    $event.$attrs.value
                                  )
                                }
                              },
                              model: {
                                value: _vm.tableTabs,
                                callback: function($$v) {
                                  _vm.tableTabs = $$v
                                },
                                expression: "tableTabs"
                              }
                            },
                            _vm._l(_vm.selectedName, function(item) {
                              return _c("el-tab-pane", {
                                key: item.id,
                                attrs: {
                                  label: item.name,
                                  value: item,
                                  name: item.name
                                }
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "forecast-chart-controls clearfix pull-right push-10-t"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  ["Group", "Vertical", "Location"].includes(
                                    _vm.reportTypeFilter
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group mr-3 mb-0 role-dropdown custom-gap"
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "control-label" },
                                            [_vm._v("Role")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: "Choose a Role"
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.loadForecastData()
                                                }
                                              },
                                              model: {
                                                value: _vm.roleOptions,
                                                callback: function($$v) {
                                                  _vm.roleOptions = $$v
                                                },
                                                expression: "roleOptions"
                                              }
                                            },
                                            _vm._l(_vm.roles, function(role) {
                                              return _c("el-option", {
                                                key: role.id,
                                                attrs: {
                                                  label: role.name,
                                                  value: role.id
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bicheck-inline forecast-bicheck custom-gap-radio"
                                    },
                                    [
                                      _vm.reportTypeFilter !== "Talent"
                                        ? _c(
                                            "vxe-radio",
                                            {
                                              attrs: {
                                                name: "xtabs",
                                                label: "available"
                                              },
                                              model: {
                                                value: _vm.selectedOption,
                                                callback: function($$v) {
                                                  _vm.selectedOption = $$v
                                                },
                                                expression: "selectedOption"
                                              }
                                            },
                                            [_vm._v("Available")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.reportTypeFilter !== "Talent"
                                        ? _c(
                                            "vxe-radio",
                                            {
                                              attrs: {
                                                name: "xtabs",
                                                label: "planned"
                                              },
                                              model: {
                                                value: _vm.selectedOption,
                                                callback: function($$v) {
                                                  _vm.selectedOption = $$v
                                                },
                                                expression: "selectedOption"
                                              }
                                            },
                                            [_vm._v("Planned")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm.selectedOption === "planned" &&
                    _vm.viewType === "Talent"
                      ? _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { "margin-bottom": "10px" }
                          },
                          [_vm._m(1)]
                        )
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.detailedForecastDataloading,
                        expression: "detailedForecastDataloading"
                      }
                    ],
                    staticClass: "col-md-12",
                    class: {
                      "no-data":
                        !_vm.selectedName.length ||
                        !_vm.detailedForecastedCollection.length
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.selectedOption === "planned" ||
                              _vm.reportTypeFilter === "Talent",
                            expression:
                              "selectedOption === 'planned' || reportTypeFilter === 'Talent'"
                          }
                        ]
                      },
                      [
                        _c(
                          "vxe-table",
                          {
                            ref: "forecastBreakdownTable",
                            staticClass: "forecastReportScrollbar",
                            attrs: {
                              border: "",
                              stripe: "",
                              "tree-config": { rowField: "_XID" },
                              "show-header-overflow": "",
                              "show-overflow": "",
                              "highlight-hover-row": "",
                              data: _vm.detailedDataResponse,
                              "max-height": "500"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "empty",
                                fn: function() {
                                  return [
                                    _c("span", {
                                      staticStyle: { color: "black" }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [
                            _c("vxe-table-column", {
                              attrs: {
                                field: "name",
                                title: "Name",
                                fixed: "left",
                                width: "200"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("div", [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "m-link m--font-bolder link-table",
                                            attrs: {
                                              target: "_blank",
                                              href: scope.row.href
                                            }
                                          },
                                          [_vm._v(_vm._s(scope.row.name))]
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _vm.reportTypeFilter === "Project"
                              ? _c("vxe-table-column", {
                                  attrs: {
                                    field: "projectRole",
                                    title: "Project Role",
                                    "min-width": "150"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.projectRoleDataResponse(
                                                    scope.row.name
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1156924658
                                  )
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.tableForecastHeaders, function(header) {
                              return _c("vxe-table-column", {
                                key: header,
                                attrs: {
                                  field: header,
                                  "min-width": "120",
                                  title: header,
                                  sortable: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row[header]))
                                          ]),
                                          _vm._v(" "),
                                          header !== "date" &&
                                          header !== "total" &&
                                          _vm.viewType === "Talent"
                                            ? _c("span", [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      _vm.holidayDataResponse(
                                                        scope.row.name,
                                                        header
                                                      )
                                                    ) +
                                                    ")"
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            })
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.selectedOption === "available" &&
                              _vm.reportTypeFilter !== "Talent",
                            expression:
                              "selectedOption === 'available'  && reportTypeFilter !== 'Talent' "
                          }
                        ]
                      },
                      [
                        _c(
                          "vxe-table",
                          {
                            ref: "forecastBreakdownTable",
                            staticClass: "forecastReportScrollbar",
                            attrs: {
                              border: "",
                              stripe: "",
                              "tree-config": { rowField: "_XID" },
                              "show-header-overflow": "",
                              "show-overflow": "",
                              "highlight-hover-row": "",
                              data: _vm.detailedDataResponse,
                              "max-height": "500"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "empty",
                                fn: function() {
                                  return [
                                    _c("span", {
                                      staticStyle: { color: "black" }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [
                            _c("vxe-table-column", {
                              attrs: {
                                field: "name",
                                title: "Name",
                                fixed: "left",
                                width: "200"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("div", [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "m-link m--font-bolder link-table",
                                            attrs: {
                                              target: "_blank",
                                              href: scope.row.href
                                            }
                                          },
                                          [_vm._v(_vm._s(scope.row.name))]
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ])
                            }),
                            _vm._v(" "),
                            _vm.reportTypeFilter === "Project"
                              ? _c("vxe-table-column", {
                                  attrs: {
                                    field: "projectRole",
                                    title: "Project Role",
                                    "min-width": "150"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.projectRoleDataResponse(
                                                    scope.row.name
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1156924658
                                  )
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.tableForecastHeaders, function(header) {
                              return _c("vxe-table-column", {
                                key: header,
                                attrs: {
                                  field: header,
                                  "min-width": "120",
                                  title: header,
                                  sortable: ""
                                }
                              })
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.detailedForecastDataloading,
                        expression: "detailedForecastDataloading"
                      }
                    ],
                    staticClass: "col-md-12",
                    class: {
                      "no-data":
                        !_vm.selectedName.length ||
                        !_vm.chartDetailedForecastedCollection.length
                    }
                  },
                  [
                    _vm.detailedForecastedHours.length < 25
                      ? _c("LineChart", {
                          attrs: {
                            data: _vm.chartDetailedForecastedHours,
                            yAxisLabel: "Hours Per " + _vm.viewType,
                            xAxisKey: "date",
                            balloonTextValuePostfix: "h",
                            collection: _vm.chartDetailedForecastedCollection,
                            scrollBar: false,
                            showBalloonText: false,
                            threshold: _vm.detailedChartThreshold
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-portlet__head-caption" }, [
      _c("div", { staticClass: "m-portlet__head-title" }, [
        _c("span", { staticClass: "m-portlet__head-icon" }, [
          _c("i", { staticClass: "flaticon-analytics" })
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "m-portlet__head-text" }, [
          _vm._v("Talent Forecasting Report")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticStyle: { color: "#f33e5c" } }, [
        _vm._v(
          "\n                        Note: Hours are displayed in the format: Project Hours (Holiday/Vacation Hours).\n                    "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }