var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagination-container" }, [
    _vm.totalNoOfProjects > 0 && _vm.pageNos.length > 1
      ? _c("div", { staticClass: "pull-left" }, [
          _c("nav", { staticClass: "d-inline-block" }, [
            _c(
              "ul",
              { staticClass: "pagination pagination-md" },
              [
                _c("li", { staticClass: "page-item" }, [
                  _vm.currentPage >= 2
                    ? _c(
                        "a",
                        {
                          staticClass: "page-link",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.goToFirstPage()
                            }
                          }
                        },
                        [_vm._v("« First")]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.currentPage > 1
                  ? _c("li", { staticClass: "page-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "page-link",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.prevPage()
                            }
                          }
                        },
                        [_vm._v("‹ Prev")]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.pageNos[0] !== 1
                  ? _c("li", { staticClass: "page-item disabled" }, [
                      _c(
                        "a",
                        { staticClass: "page-link", attrs: { href: "#" } },
                        [_vm._v("…")]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.pageNos, function(page) {
                  return _c(
                    "li",
                    {
                      key: page,
                      staticClass: "page-item",
                      class: { active: page === _vm.currentPage },
                      on: {
                        click: function($event) {
                          return _vm.moveToPage(page)
                        }
                      }
                    },
                    [
                      _c(
                        "a",
                        { staticClass: "page-link", attrs: { href: "#" } },
                        [_vm._v(_vm._s(page))]
                      )
                    ]
                  )
                }),
                _vm._v(" "),
                Math.ceil(_vm.totalNoOfProjects / _vm.itemsPerPage) >
                _vm.pageNos[_vm.pageNos.length - 1]
                  ? _c("li", { staticClass: "page-item disabled" }, [
                      _c(
                        "a",
                        { staticClass: "page-link", attrs: { href: "#" } },
                        [_vm._v("…")]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentPage <
                Math.ceil(_vm.totalNoOfProjects / _vm.itemsPerPage)
                  ? _c("li", { staticClass: "page-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "page-link",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.nextPage()
                            }
                          }
                        },
                        [_vm._v("Next ›")]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentPage <
                Math.ceil(_vm.totalNoOfProjects / _vm.itemsPerPage)
                  ? _c("li", { staticClass: "page-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "page-link",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.goTolastPage()
                            }
                          }
                        },
                        [_vm._v("Last »")]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.totalNoOfProjects > 0 && _vm.pageNos.length > 1
      ? _c("div", { staticClass: "pull-right page-entries" }, [
          _vm._v("\n    Displaying " + _vm._s(_vm.name) + "\n    "),
          _c("b", [
            _vm._v(
              _vm._s(_vm.getVisibleRecordPosition.start) +
                " - " +
                _vm._s(_vm.getVisibleRecordPosition.end)
            )
          ]),
          _vm._v(" of\n    "),
          _c("b", [_vm._v(_vm._s(_vm.totalNoOfProjects))]),
          _vm._v(" in total\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.totalNoOfProjects > 0 && _vm.pageNos.length === 1
      ? _c("div", { staticClass: "pull-right page-entries" }, [
          _vm._v("\n    Displaying\n    "),
          _c("b", [_vm._v("all " + _vm._s(_vm.totalNoOfProjects))]),
          _vm._v("\n    " + _vm._s(_vm.name) + "\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "clear" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }