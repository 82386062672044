var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Please wait while sync is in progress",
            visible: _vm.open_,
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.open_ = $event
            },
            close: _vm.emitClose
          }
        },
        [
          _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: true,
                expression: "true"
              }
            ]
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { id: "pulsate-crazy-target", type: "primary" },
                  on: { click: _vm.emitClose }
                },
                [_vm._v("Run in background")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }