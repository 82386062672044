var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-content" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c(
          "div",
          {
            staticClass: "m-portlet m-portlet--head-sm",
            attrs: { "m-portlet": "true", id: "fullscreen-body" }
          },
          [
            _c("div", { staticClass: "m-portlet__head" }, [
              _c("div", { staticClass: "m-portlet__head-caption" }, [
                _c("div", { staticClass: "m-portlet__head-title" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("h3", { staticClass: "m-portlet__head-text" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.getSelectedProjectName) +
                        " - Gantt Charts\n              "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "m-portlet__head-tools" }, [
                _c("ul", { staticClass: "m-portlet__nav" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass:
                        "m-portlet__nav-item m-dropdown m-dropdown--arrow m-dropdown--align-right",
                      attrs: { "m-dropdown-toggle": "hover" }
                    },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("div", { staticClass: "m-dropdown__wrapper" }, [
                        _c("span", {
                          staticClass:
                            "m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "m-dropdown__inner" }, [
                          _c("div", { staticClass: "m-dropdown__body" }, [
                            _c("div", { staticClass: "m-dropdown__content" }, [
                              _c("ul", { staticClass: "m-nav" }, [
                                _vm._m(3),
                                _vm._v(" "),
                                _c("li", { staticClass: "m-nav__item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "m-nav__link",
                                      attrs: {
                                        href: this.export_url("pdf"),
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "m-nav__link-icon flaticon-technology"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "m-nav__link-text" },
                                        [_vm._v("PDF")]
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", { staticClass: "m-nav__item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "m-nav__link",
                                      attrs: {
                                        href: this.export_url("png"),
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "m-nav__link-icon flaticon-photo-camera"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "m-nav__link-text" },
                                        [_vm._v("PNG")]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(4)
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "m-portlet__body" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Project Name")
                        ]),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              placeholder: "Pick a Project"
                            },
                            on: {
                              input: function($event) {
                                return _vm.projectSelectionHandler()
                              }
                            },
                            model: {
                              value: _vm.selected_project,
                              callback: function($$v) {
                                _vm.selected_project = $$v
                              },
                              expression: "selected_project"
                            }
                          },
                          _vm._l(_vm.projects, function(project) {
                            return _c("el-option", {
                              key: project.id,
                              attrs: { label: project.name, value: project.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Date range")
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "fa fa-info-circle font-blue push-2-l",
                          attrs: {
                            id: "gantt_date_range_info",
                            "data-toggle": "m-tooltip",
                            "data-placement": "top",
                            title:
                              "By default the selected range is set between the current date and a month after the current date."
                          }
                        }),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            "range-separator": "To",
                            "start-placeholder": "Start date",
                            "end-placeholder": "End date",
                            format: "MM-dd-yyyy",
                            clearable: false,
                            "picker-options": _vm.pickerOptions,
                            disabled: _vm.show_lifetime
                          },
                          on: {
                            input: function($event) {
                              return _vm.refreshGanttData()
                            }
                          },
                          model: {
                            value: _vm.selected_date_range,
                            callback: function($$v) {
                              _vm.selected_date_range = $$v
                            },
                            expression: "selected_date_range"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-5" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Report Period")
                        ]),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-text": "Lifetime",
                            "inactive-text": "Date range",
                            name: "Lifetime",
                            id: "period_toggler"
                          },
                          on: { change: _vm.toggleLifetime },
                          model: {
                            value: _vm.show_lifetime,
                            callback: function($$v) {
                              _vm.show_lifetime = $$v
                            },
                            expression: "show_lifetime"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.syncActive,
                        expression: "syncActive"
                      }
                    ],
                    staticClass: "row"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "col-md-12",
                        staticStyle: { "padding-left": "0px, z-index: 10" }
                      },
                      [
                        _c("div", { staticClass: "gantt-controls" }, [
                          _c(
                            "div",
                            { staticClass: "pull-right" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.activeTabName ===
                                        "project-gantt-tab",
                                      expression:
                                        "activeTabName==='project-gantt-tab'"
                                    }
                                  ],
                                  attrs: { size: "small" },
                                  on: { change: _vm.applyFilterProject },
                                  model: {
                                    value: _vm.selected_filter_project,
                                    callback: function($$v) {
                                      _vm.selected_filter_project = $$v
                                    },
                                    expression: "selected_filter_project"
                                  }
                                },
                                [
                                  _c("el-radio-button", {
                                    attrs: { label: "Phase" }
                                  }),
                                  _vm._v(" "),
                                  _c("el-radio-button", {
                                    attrs: { label: "Module" }
                                  }),
                                  _vm._v(" "),
                                  _c("el-radio-button", {
                                    attrs: { label: "Task" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-group",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.activeTabName ===
                                        "resource-gantt-tab",
                                      expression:
                                        "activeTabName==='resource-gantt-tab'"
                                    }
                                  ],
                                  attrs: { size: "small" },
                                  on: { change: _vm.applyFilterResource },
                                  model: {
                                    value: _vm.selected_filter_resource,
                                    callback: function($$v) {
                                      _vm.selected_filter_resource = $$v
                                    },
                                    expression: "selected_filter_resource"
                                  }
                                },
                                [
                                  _c("el-radio-button", {
                                    attrs: { label: "Resource" }
                                  }),
                                  _vm._v(" "),
                                  _c("el-radio-button", {
                                    attrs: { label: "Task" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.activeTabName === "resource-gantt-tab",
                                  expression:
                                    "activeTabName==='resource-gantt-tab'"
                                }
                              ],
                              staticClass: "pull-right timeline-select"
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    placeholder: "Select"
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.timelineSelectionHandler()
                                    }
                                  },
                                  model: {
                                    value: _vm.selected_timeline_filter,
                                    callback: function($$v) {
                                      _vm.selected_timeline_filter = $$v
                                    },
                                    expression: "selected_timeline_filter"
                                  }
                                },
                                _vm._l(_vm.options, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "pull-right",
                              staticStyle: {
                                "font-size": "12px",
                                margin: "6px"
                              }
                            },
                            [_vm._v("View by ")]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading"
                              }
                            ],
                            staticClass: "gantt-charts"
                          },
                          [
                            _c(
                              "el-tabs",
                              {
                                on: { "tab-click": _vm.handleTabClick },
                                model: {
                                  value: _vm.activeTabName,
                                  callback: function($$v) {
                                    _vm.activeTabName = $$v
                                  },
                                  expression: "activeTabName"
                                }
                              },
                              [
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "Project Gantt Chart",
                                      name: "project-gantt-tab"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.project_gantt_visibilty,
                                            expression:
                                              "project_gantt_visibilty"
                                          }
                                        ],
                                        staticClass: "viewport_height",
                                        attrs: { id: "project_gantt_chart" }
                                      },
                                      [
                                        _c("project-gantt", {
                                          ref: "gantt_chart",
                                          staticClass: "left-container",
                                          attrs: {
                                            tasks: _vm.project_gantt_data,
                                            selected_filter:
                                              _vm.selected_filter_project,
                                            selected_project:
                                              _vm.selected_project
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("no-data-handler", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.project_gantt_visibilty,
                                          expression: "!project_gantt_visibilty"
                                        }
                                      ]
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "Resource Gantt Chart",
                                      name: "resource-gantt-tab"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.resource_gantt_visibilty,
                                            expression:
                                              "resource_gantt_visibilty"
                                          }
                                        ],
                                        staticClass: "viewport_height",
                                        attrs: { id: "hot-preview" }
                                      },
                                      [
                                        _c("resource-gantt", {
                                          ref: "resource_gantt",
                                          attrs: {
                                            resource_gantt_data:
                                              _vm.visible_res_data,
                                            resource_row: _vm.resource_row,
                                            timeline_filter:
                                              _vm.selected_timeline_filter,
                                            ref_resource_data:
                                              _vm.ref_resource_data
                                          },
                                          on: {
                                            dblClickResource:
                                              _vm.onDblClickResource
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("no-data-handler", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.resource_gantt_visibilty,
                                          expression:
                                            "!resource_gantt_visibilty"
                                        }
                                      ]
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: "Sync in progress",
                      visible: _vm.dialogFormVisible,
                      "append-to-body": true
                    },
                    on: {
                      "update:visible": function($event) {
                        _vm.dialogFormVisible = $event
                      }
                    }
                  },
                  [
                    _c("div", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: true,
                          expression: "true"
                        }
                      ]
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer"
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              id: "pulsate-crazy-target",
                              type: "primary"
                            },
                            on: {
                              click: function($event) {
                                _vm.dialogFormVisible = false
                              }
                            }
                          },
                          [_vm._v("Run in background")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c("sidebar", { attrs: { project_id: _vm.selected_project } })
              ],
              1
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "m-portlet__head-icon" }, [
      _c("i", { staticClass: "flaticon-interface-3" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "m-portlet__nav-item" }, [
      _c(
        "a",
        {
          staticClass: "m-portlet__nav-link m-portlet__nav-link--icon btn",
          attrs: {
            href: "#",
            "data-clipboard": "true",
            "data-toggle": "m-tooltip",
            "data-placement": "top",
            title: "Copy tasks"
          }
        },
        [_c("i", { staticClass: "la la-copy" })]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "m-portlet__nav-link m-portlet__nav-link--icon m-dropdown__toggle",
        attrs: { href: "#" }
      },
      [_c("i", { staticClass: "la la-download" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "m-nav__section m-nav__section--first" }, [
      _c("span", { staticClass: "m-nav__section-text" }, [
        _vm._v("Export Options")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "m-portlet__nav-item" }, [
      _c(
        "a",
        {
          staticClass: "m-portlet__nav-link m-portlet__nav-link--icon",
          attrs: {
            href: "#",
            "m-portlet-tool": "fullscreen",
            id: "gantt_full_screen"
          }
        },
        [_c("i", { staticClass: "la la-expand" })]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }