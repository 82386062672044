var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "invoice-dialog",
      attrs: { title: "Edit Invoice", visible: _vm.open_ },
      on: {
        "update:visible": function($event) {
          _vm.open_ = $event
        },
        close: _vm.emitClose
      }
    },
    [
      _c("div", { staticClass: "button-wrapper" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.addInvoice()
              }
            }
          },
          [_vm._v("Add Invoice")]
        )
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: _vm.invoiceTableKey,
          staticClass: "invoice-table",
          staticStyle: { width: "100%" },
          attrs: { border: "", stripe: "", data: _vm.currentInvoices }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "date",
              label: "Invoice Date",
              width: "'100%'"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        format: "MM/dd/yyyy",
                        placeholder: "Pick a date",
                        clearable: false
                      },
                      model: {
                        value: scope.row.invoice_date,
                        callback: function($$v) {
                          _vm.$set(scope.row, "invoice_date", $$v)
                        },
                        expression: "scope.row.invoice_date"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "number",
              label: "Invoice Number",
              width: "'100%'"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: scope.row.invoice_no,
                          expression: "scope.row.invoice_no"
                        }
                      ],
                      staticClass: "el-input__inner",
                      attrs: {
                        type: "text",
                        placeholder: "Invoice Number",
                        min: 0,
                        label: "Invoice Number",
                        size: "mini"
                      },
                      domProps: { value: scope.row.invoice_no },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(scope.row, "invoice_no", $event.target.value)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "right",
              prop: "amount",
              label: "Invoice Amount",
              width: "'100%'"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("input", {
                      directives: [
                        {
                          name: "currency",
                          rawName: "v-currency",
                          value: {
                            currency: "USD",
                            locale: "en-US",
                            "auto-decimal-mode": true
                          },
                          expression:
                            "{currency: 'USD', locale: 'en-US','auto-decimal-mode':true}"
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: scope.row.value,
                          expression: "scope.row.value"
                        }
                      ],
                      staticClass: "el-input__inner",
                      attrs: {
                        type: "text",
                        placeholder: "Invoice Amount",
                        controls: false,
                        min: 0,
                        label: "Invoice Amount",
                        size: "mini"
                      },
                      domProps: { value: scope.row.value },
                      on: {
                        keyup: function($event) {
                          return _vm.checkForMaxValue(scope.row.value, $event)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(scope.row, "value", $event.target.value)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "Action", width: "'100%'" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.checkValidation(scope.row)
                          }
                        }
                      },
                      [_vm._v("Save")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-danger cursor-pointer",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.deleteInvoice(scope.row)
                          }
                        }
                      },
                      [_vm._v("Delete")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary cursor-pointer",
              attrs: { type: "button", "close-on-click-modal": true },
              on: { click: _vm.emitClose }
            },
            [_vm._v("Close")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }