var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("HotTable", {
    ref: "hotTableComponent",
    attrs: { settings: _vm.resource_gantt_settings }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }