var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          ref: "selectFilter",
          staticClass: "br-2rem select-filter__wrap",
          attrs: {
            "value-key": "id",
            placeholder: _vm.placeholder,
            multiple: _vm.multiple,
            "multiple-limit": _vm.multipleLimit
          },
          on: {
            input: function($event) {
              return _vm.emitEvent()
            }
          },
          model: {
            value: _vm.selected_,
            callback: function($$v) {
              _vm.selected_ = $$v
            },
            expression: "selected_"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.name, value: item }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }