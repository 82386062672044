var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "layeredColumnChart",
    style: this.chart_style,
    attrs: { id: "layered-column-chart" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }