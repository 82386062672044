var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-xl-6 col-lg-12" }, [
    _c("div", { staticClass: "m-portlet  m-portlet--full-height " }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "m-portlet__body" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              },
              {
                name: "show",
                rawName: "v-show",
                value: _vm.critical_dates_data.length > 0,
                expression: "critical_dates_data.length > 0"
              }
            ],
            staticClass: "m-scrollable",
            attrs: {
              id: "critical_date_container",
              "data-scrollable": "true",
              "data-height": "425",
              "data-mobile-height": "300"
            }
          },
          [
            _c("div", { staticClass: "m-timeline-2" }, [
              _c(
                "div",
                {
                  staticClass:
                    "m-timeline-2__items  m--padding-top-25 m--padding-bottom-30"
                },
                _vm._l(_vm.critical_dates_data, function(critical_date) {
                  return _c(
                    "div",
                    {
                      staticClass: "m-timeline-2__item",
                      attrs: { id: critical_date.id }
                    },
                    [
                      _c("span", { staticClass: "m-timeline-2__item-time" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("bit_date_format")(critical_date.start_date)
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "m-timeline-2__item-cricle" }, [
                        _c("i", {
                          class: _vm.valueBasedCricleClass(
                            critical_date.criticality_level
                          )
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "m-timeline-2__item-text  m--padding-top-5",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.highlightKeywords(
                              critical_date.name,
                              critical_date.matched_keywords
                            )
                          )
                        }
                      })
                    ]
                  )
                }),
                0
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.critical_dates_data.length == 0,
                expression: "critical_dates_data.length == 0"
              }
            ],
            staticClass: "no-data-handler"
          },
          [_c("span", [_vm._v("No critical dates available.")])]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-portlet__head" }, [
      _c("div", { staticClass: "m-portlet__head-caption" }, [
        _c("div", { staticClass: "m-portlet__head-title" }, [
          _c("h3", { staticClass: "m-portlet__head-text" }, [
            _vm._v("\n            Important/Critical Dates\n          ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-portlet__head-tools" }, [
        _c(
          "a",
          {
            staticClass: "m-portlet__nav-link m-portlet__nav-link--icon btn",
            attrs: {
              href: "javascript:void(0);",
              "data-toggle": "m-tooltip",
              title:
                "This panel contains a list of tasks which might be deemed critical to the project based on the highlighted keywords in blue.",
              "data-placement": "left"
            }
          },
          [
            _c("i", {
              staticClass:
                "m-nav__link-icon flaticon-info m--icon-font-size-lg3"
            })
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }