import { render, staticRenderFns } from "./ProjectBurnSummary.vue?vue&type=template&id=771ab8a1&"
import script from "./project-burn-summary.js?vue&type=script&lang=js&"
export * from "./project-burn-summary.js?vue&type=script&lang=js&"
import style0 from "./project-burn-summary.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/web/bitool.softwaysolutions.com/htdocs/releases/20201012055253/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('771ab8a1')) {
      api.createRecord('771ab8a1', component.options)
    } else {
      api.reload('771ab8a1', component.options)
    }
    module.hot.accept("./ProjectBurnSummary.vue?vue&type=template&id=771ab8a1&", function () {
      api.rerender('771ab8a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend/project-plan/burn-report/project-burn-summary/ProjectBurnSummary.vue"
export default component.exports