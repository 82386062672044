var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "table",
        { staticClass: "table table-bordered table-striped table-hover" },
        [
          _c("thead", [
            _c(
              "tr",
              { staticClass: "heading", attrs: { role: "row" } },
              _vm._l(_vm.headers, function(header, index) {
                return _c(
                  "th",
                  {
                    key: index,
                    style: [
                      header.type == "action-buttons" || header.type == "card"
                        ? { width: header.width + "!important" }
                        : {}
                    ]
                  },
                  [
                    header.sort.required
                      ? _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.getFilterData(true, header.key)
                              }
                            }
                          },
                          [_vm._v(_vm._s(header.label))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    header.sort.required
                      ? _c("i", {
                          staticClass: "sort_link",
                          class: header.sort.icon
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !header.sort.required
                      ? _c("span", [_vm._v(_vm._s(header.label))])
                      : _vm._e()
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "tr",
              { staticClass: "filter", attrs: { id: "search-form" } },
              _vm._l(_vm.headers, function(header, index) {
                return _c(
                  "td",
                  { key: index },
                  [
                    header.search.required && header.search.type === "text"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: header.search.defaultValue,
                              expression: "header.search.defaultValue"
                            }
                          ],
                          staticClass:
                            "form-control form-filter form-control-sm m-input",
                          attrs: {
                            placeholder: header.search.placeholder,
                            type: "search"
                          },
                          domProps: { value: header.search.defaultValue },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.getFilterData(null, null, true)
                            },
                            blur: function($event) {
                              return _vm.getFilterData(null, null, true)
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                header.search,
                                "defaultValue",
                                $event.target.value
                              )
                            }
                          }
                        })
                      : header.search.required &&
                        header.search.type === "dropdown"
                      ? _c(
                          "el-select",
                          {
                            staticClass: "form-fix",
                            attrs: {
                              size: "mini",
                              placeholder: header.search.placeholder
                            },
                            on: {
                              change: function($event) {
                                return _vm.getFilterData(null, null, true)
                              }
                            },
                            model: {
                              value: header.search.selectedValue,
                              callback: function($$v) {
                                _vm.$set(header.search, "selectedValue", $$v)
                              },
                              expression: "header.search.selectedValue"
                            }
                          },
                          _vm._l(header.search.options, function(
                            option,
                            index
                          ) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: option.title, value: option.id }
                            })
                          }),
                          1
                        )
                      : header.search.required && header.search.type === "date"
                      ? _c("el-date-picker", {
                          staticClass: "form-fix",
                          attrs: {
                            size: "mini",
                            clearable: false,
                            type: "date",
                            placeholder: header.search.placeholder
                          },
                          on: {
                            change: function($event) {
                              return _vm.getFilterData(null, null, true)
                            }
                          },
                          model: {
                            value: header.search.selectedValue,
                            callback: function($$v) {
                              _vm.$set(header.search, "selectedValue", $$v)
                            },
                            expression: "header.search.selectedValue"
                          }
                        })
                      : header.search.required &&
                        header.search.type === "daterange"
                      ? _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "picker-options": _vm.datePickerOptions,
                            "range-separator": "-",
                            "start-placeholder": "Start",
                            "end-placeholder": "End",
                            size: "mini",
                            format: "MM/dd/yyyy",
                            "unlink-panels": ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.getFilterData(null, null, true)
                            }
                          },
                          model: {
                            value: header.search.selectedValue,
                            callback: function($$v) {
                              _vm.$set(header.search, "selectedValue", $$v)
                            },
                            expression: "header.search.selectedValue"
                          }
                        })
                      : [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-success m-btn m-btn--icon m-btn--icon-only",
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.getFilterData(null, null, true)
                                }
                              }
                            },
                            [_c("i", { staticClass: "la la-search" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-danger m-btn m-btn--icon m-btn--icon-only",
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.resetFilter()
                                }
                              }
                            },
                            [_c("i", { staticClass: "la la-close" })]
                          )
                        ]
                  ],
                  2
                )
              }),
              0
            )
          ]),
          _vm._v(" "),
          _vm.tableData && _vm.tableData.length
            ? _c(
                "tbody",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loader,
                      expression: "loader"
                    }
                  ]
                },
                _vm._l(_vm.tableData, function(item, dataIndex) {
                  return _c(
                    "tr",
                    {
                      key: dataIndex,
                      staticClass: "filter",
                      attrs: { role: "row" }
                    },
                    _vm._l(_vm.headers, function(header, headerIndex) {
                      return _c(
                        "td",
                        {
                          key: headerIndex,
                          class: { "align-right": header.type == "currency" }
                        },
                        [
                          header.type == "text"
                            ? [_vm._v(_vm._s(item[header.key] || "NA"))]
                            : header.type == "textarea"
                            ? [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      trigger: "hover",
                                      placement: "bottom"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "text-left" }, [
                                      _vm._v(_vm._s(item[header.key]))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "name-wrapper",
                                        attrs: { slot: "reference" },
                                        slot: "reference"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              item[header.key] &&
                                                item[header.key].length > 20
                                                ? item[header.key].substring(
                                                    0,
                                                    20
                                                  ) + "..."
                                                : item[header.key]
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            : header.type == "card"
                            ? [
                                _vm._v(
                                  _vm._s(
                                    "**** **** **** " + item[header.key].number
                                  )
                                )
                              ]
                            : header.type == "date"
                            ? [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("bit_date_format")(
                                        item[header.key]
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            : header.type == "currency"
                            ? [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("currency")(item[header.key])
                                    ) +
                                    "\n          "
                                )
                              ]
                            : header.type == "dropdown"
                            ? [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "form-fix",
                                    attrs: { size: "mini" },
                                    on: {
                                      change: function($event) {
                                        return _vm.rowDataUpdated(
                                          item.id,
                                          header.key,
                                          item[header.key]
                                        )
                                      }
                                    },
                                    model: {
                                      value: item[header.key],
                                      callback: function($$v) {
                                        _vm.$set(item, header.key, $$v)
                                      },
                                      expression: "item[header.key]"
                                    }
                                  },
                                  _vm._l(header.editOptions, function(
                                    option,
                                    opIndex
                                  ) {
                                    return _c("el-option", {
                                      key: opIndex,
                                      attrs: {
                                        label: option.title,
                                        value: option.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ]
                            : header.type == "dropdown-custom"
                            ? [
                                _c(
                                  "el-select",
                                  {
                                    ref:
                                      "expenseType" + dataIndex + headerIndex,
                                    refInFor: true,
                                    staticClass: "form-fix list-item-fix",
                                    attrs: { size: "mini" },
                                    on: {
                                      "visible-change": function($event) {
                                        return _vm.saveOldExpenseDetails(
                                          $event,
                                          item.expense_charged_to_client,
                                          item.expense_charged_project_name,
                                          item.expense_charged_client_name
                                        )
                                      },
                                      change: function($event) {
                                        return _vm.saveExpenseDetails(
                                          "expenseType" +
                                            dataIndex +
                                            headerIndex,
                                          item.id,
                                          header.key,
                                          item[header.key]
                                        )
                                      }
                                    },
                                    model: {
                                      value: item[header.key],
                                      callback: function($$v) {
                                        _vm.$set(item, header.key, $$v)
                                      },
                                      expression: "item[header.key]"
                                    }
                                  },
                                  [
                                    _vm._l(header.editOptions, function(
                                      option,
                                      opIndex
                                    ) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: opIndex,
                                          attrs: {
                                            label: option.title,
                                            value: option.id
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticStyle: { float: "left" } },
                                            [_vm._v(_vm._s(option.title))]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    item[header.key] === 1
                                      ? _c(
                                          "div",
                                          { staticClass: "kt-subdropdown" },
                                          [
                                            _c(
                                              "h3",
                                              {
                                                staticClass:
                                                  "kt-subdropdown__title"
                                              },
                                              [
                                                _vm._v(
                                                  "Project Expense details"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "text-form" },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "form-label" },
                                                  [_vm._v("Charged to client:")]
                                                ),
                                                _vm._v(" "),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: { label: "Yes" },
                                                    model: {
                                                      value:
                                                        item.expense_charged_to_client,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "expense_charged_to_client",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.expense_charged_to_client"
                                                    }
                                                  },
                                                  [_vm._v("Yes")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: { label: "No" },
                                                    model: {
                                                      value:
                                                        item.expense_charged_to_client,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "expense_charged_to_client",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.expense_charged_to_client"
                                                    }
                                                  },
                                                  [_vm._v("No")]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "text-form" },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "form-label" },
                                                  [_vm._v("Project name:")]
                                                ),
                                                _vm._v(" "),
                                                _c("el-input", {
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "Project name"
                                                  },
                                                  model: {
                                                    value:
                                                      item.expense_charged_project_name,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "expense_charged_project_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.expense_charged_project_name"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "text-form" },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "form-label" },
                                                  [_vm._v("Client name:")]
                                                ),
                                                _vm._v(" "),
                                                _c("el-input", {
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "Client name"
                                                  },
                                                  model: {
                                                    value:
                                                      item.expense_charged_client_name,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "expense_charged_client_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.expense_charged_client_name"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "button-panel" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-secondary btn-sm",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.cancelExpenseDetailsUpdate(
                                                          "expenseType" +
                                                            dataIndex +
                                                            headerIndex,
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Cancel")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary btn-sm",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.updateExpenseDetails(
                                                          "expenseType" +
                                                            dataIndex +
                                                            headerIndex,
                                                          item.id,
                                                          item.expense_charged_to_client,
                                                          item.expense_charged_project_name,
                                                          item.expense_charged_client_name
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Save")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            : [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-outline-warning m-btn m-btn--icon m-btn--icon-only m-btn--pill",
                                    attrs: {
                                      href: "javascript:void(0)",
                                      "data-container": "body",
                                      "data-toggle": "m-tooltip",
                                      "data-placement": "top",
                                      title: "Edit notes",
                                      "data-original-title": "Edit notes"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openNotesModalHandler(item)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "flaticon-notepad" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-badge",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      value: item["attachments"].length || 0
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-outline-accent m-btn m-btn--icon m-btn--icon-only m-btn--pill",
                                        attrs: {
                                          href: "javascript:void(0)",
                                          "data-container": "body",
                                          "data-toggle": "m-tooltip",
                                          "data-placement": "top",
                                          title: "Edit attachments",
                                          "data-original-title":
                                            "Edit attachments"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openAttachmentsModalHandler(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "flaticon-attachment"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]
                        ],
                        2
                      )
                    }),
                    0
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tableData && _vm.tableData.length == 0
            ? _c("tbody", [
                _c("tr", [
                  _c(
                    "td",
                    { attrs: { colspan: "12" } },
                    [_c("center", [_vm._v("No Records Found.")])],
                    1
                  )
                ])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("notes-modal", {
        attrs: { open: _vm.openNotesModal, notes: _vm.workingItem.notes },
        on: {
          "update:open": function($event) {
            _vm.openNotesModal = $event
          },
          "update:notes": function($event) {
            return _vm.$set(_vm.workingItem, "notes", $event)
          },
          notesUpdated: _vm.closeNotesModalHandler
        }
      }),
      _vm._v(" "),
      _c("attachments-modal", {
        attrs: {
          open: _vm.openAttachmentsModal,
          attachments: _vm.workingItem.attachments
        },
        on: {
          "update:open": function($event) {
            _vm.openAttachmentsModal = $event
          },
          "update:attachments": function($event) {
            return _vm.$set(_vm.workingItem, "attachments", $event)
          },
          attachmentsUpdated: _vm.closeAttachmentsModalHandler
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }