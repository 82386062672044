var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticStyle: { width: "100%", height: "450px" },
    attrs: { id: "daily_burn_chart" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }